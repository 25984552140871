import { RouteObject } from "react-router-dom";
import { LandingPage } from "@/pages/public/landing";
import { Impressum } from "@/pages/public/Impressum";
import { Cookies } from "@/pages/public/Cookies";
import { PrivacyPolicy } from "@/pages/public/PrivacyPolicy";
import { TermsConditions } from "@/pages/public/TermsConditions";
import { Signup } from "@/pages/auth/Signup";
import { Login } from "@/pages/auth/Login";
import { AccountRemoved } from "@/pages/auth/AccountRemoved";

export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <LandingPage />,
    children: [
      {
        path: "auth/signup",
        element: <Signup />,
      },
      {
        path: "auth/login",
        element: <Login />,
      },
      {
        path: "auth/account-removed",
        element: <AccountRemoved />,
      },
    ],
  },
  {
    path: "/impressum",
    element: <Impressum />,
  },
  {
    path: "/gdpr",
    element: <Cookies />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },
];

import { T } from "@tolgee/react";
import { useContext } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { PopupSkeleton } from "@/components/Popup/PopupSkeleton";
import { CoinsGoingOut } from "@/assets/illustrations";
import { useNavigate } from "react-router-dom";
import { Button, LinkButton } from "@/components/Inputs/Button";

export const VerifyDocuments: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!;

  const navigate = useNavigate();

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={CoinsGoingOut} alt="" />
        </div>
        <h1 className="text-2xl mb-1">{`€${currentUser?.totalBalance.toFixed(2)}`}</h1>
        <h6 className="text-sm text-gray-shuttle-soft mb-6">
          <T keyName="components.payments.wallet.verifyDocuments.info" />
        </h6>
        <h1 className="text-2xl mb-4">
          <T keyName="components.payments.wallet.verifyDocuments.title" />
        </h1>
        <h6 className="text-sm text-gray-shuttle">
          <T keyName="components.payments.wallet.verifyDocuments.warning" />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.payments.wallet.verifyDocuments.btnCancel" />
          </Button>
          <LinkButton
            to={
              currentUser?.idDocumentsStatus !== "ACCEPTED"
                ? "../../bank-details/documents-verification"
                : "../../bank-details"
            }
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T keyName="components.payments.wallet.verifyDocuments.btnVerify" />
          </LinkButton>
        </div>
      </div>
    </PopupSkeleton>
  );
};

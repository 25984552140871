import { T, useTranslate } from "@tolgee/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { SubscriptionPopupContainer } from "./PopupContainer";
import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/Inputs/Button";
import { CheckCloud } from "@/assets/illustrations";
import { ImgProfile } from "@/components/ImgProfile";
import { CheckBall } from "@/assets/icons";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { toastError } from "@/utils/toast";
import { gql } from "@/gql";
import invariant from "tiny-invariant";
import * as yup from "yup";
import { TextInput } from "@/components/Inputs";
import { useFormik } from "formik";
import { getErrorMessage } from "@/utils/errors";

const SERVICE_DETAILS = gql(`
  query getServiceDetailsForJoinSuccess($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      owner {
        fullName
        firstName
        avatar
      }
      provider {
        name
        brand
        joinerEmailRequired
      }
    }
  }
`);

const JOIN_SERVICE = gql(`
  mutation JoinService(
    $serviceId: String!
    $requestById: String!
    $transactionId: String!
  ) {
    join(
      data: {
        serviceId: $serviceId
        requestById: $requestById
        transactionId: $transactionId
      }
    ) {
      id
    }
  }
`);

const UPDATE_REQUEST = gql(`
    mutation UpdateRequest(
      $joinerEmail: String!
      $requestId: String
    ){
      updateRequest(
        data: {
          joinerEmail:$joinerEmail
        } 
        where: {
          id:$requestId
        }
      ) {
      joinerEmail
    }
  }
`);

export const JoinSubscriptionSuccessPopup: React.FC = () => {
  const location = useLocation();
  const { id, serviceId } = useParams<{ id: string; serviceId: string }>();
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext)!;

  const [loading, setLoading] = useState(true);
  const [requestID, setRequestID] = useState<string>("");

  const { data, loading: serviceLoading } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId: serviceId! },
  });

  const { t } = useTranslate();
  const [updateRequest] = useMutation(UPDATE_REQUEST);

  const validationSchema = yup.object().shape({
    joinerEmail: yup.string().when([], () => {
      if (data?.service.provider.joinerEmailRequired) {
        return yup
          .string()
          .email(t("form.inputs.errors.validEmail"))
          .required("Please enter an email");
      } else {
        return yup.string().email(t("form.inputs.errors.validEmail"));
      }
    }),
  });

  const formik = useFormik({
    initialValues: {
      joinerEmail: "",
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        updateRequest({
          variables: {
            joinerEmail: values.joinerEmail,
            requestId: requestID,
          },
        }).then(() =>
          navigate("/overview/subscriptions", { state: "refetch" }),
        );
      } catch (error) {
        toastError(t("toastMessages.submitJoinerEmail.error"));
      }
    },
  });

  const [joinService] = useMutation(JOIN_SERVICE);

  useEffect(() => {
    const joinServiceMutation = async () => {
      if (!serviceLoading || !serviceId || !currentUser?.id) return;

      // retriving the transaction token from the search params in the url
      // FIXME: fix this abomination
      const transactionId = location.search.split("=")[1];

      try {
        const result = await joinService({
          variables: {
            serviceId,
            requestById: currentUser?.id,
            transactionId,
          },
        });

        setRequestID(result.data ? result.data.join.id : "");
        setLoading(false);
      } catch (error: unknown) {
        toastError(getErrorMessage(error));
      }
    };

    joinServiceMutation();
  }, [
    serviceLoading,
    navigate,
    serviceId,
    location.search,
    joinService,
    currentUser?.id,
  ]);

  let content;
  if (loading) content = <Spinner />;
  else {
    invariant(data, "Data should be resolved");

    const steps = [
      {
        key: "second",
        img: (
          <div className="w-[75px] h-[75px] relative">
            <ImgProfile img={data.service.owner.avatar} />
            <div className="absolute bottom-[-5px] right-0">
              <CheckBall />
            </div>
          </div>
        ),
        joinerEmailRequired: false,
      },
      {
        key: "third",
        img: (
          <div className="border border-gray p-2 rounded-lg">
            <div
              className="w-12 h-12 bg-white bg-cover bg-no-repeat bg-center rounded"
              style={{
                backgroundImage: `url("${data.service.provider.brand}")`,
              }}
            />
          </div>
        ),
        joinerEmailRequired: data.service.provider.joinerEmailRequired,
      },
    ];

    content = (
      <>
        <div className="flex justify-center mb-6">
          <img src={CheckCloud} alt="" />
        </div>

        <h1 className="text-2xl font-medium mb-2 text-center">
          <T keyName="components.joinSubscription.steps.success.title" />
        </h1>

        <p className="text-sm text-gray-shuttle-soft mb-4 text-center">
          <T keyName="components.joinSubscription.steps.success.subtitle" />
        </p>

        <form onSubmit={formik.handleSubmit}>
          <div>
            {steps.map((item) => (
              <div
                className="flex py-3 px-2 first:bg-blue-dodger/10 first:rounded-2xl first:mb-1"
                key={item.key}
              >
                <div className="w-[120px] flex-shrink-0 flex justify-center max-md:h-[75px]">
                  {item.img}
                </div>
                <div className="ml-3">
                  <div className="text-lg font-medium text-gray-100 mb-1 max-md:mb-2">
                    <T
                      keyName={`components.joinSteps.${item.key}.title`}
                      params={{
                        owner: () => data.service.owner.firstName,
                        service: () => data.service.provider.name,
                      }}
                    />
                  </div>
                  {item.joinerEmailRequired && (
                    <>
                      <div className="text-sm text-gray-shuttle-soft">
                        <T
                          keyName={`components.joinSteps.${item.key}.subtitle.joinerEmail`}
                          params={{
                            owner: () => data.service.owner.firstName,
                            service: () => data.service.provider.name,
                          }}
                        />
                      </div>
                      <div className="flex flex-col mt-2 mb-1 max-md:mb-2">
                        <TextInput
                          name="joinerEmail"
                          formik={formik}
                          label={t("form.inputs.labels.joinerEmail", {
                            provider: () =>
                              data.service.provider.name.split(" ")[0],
                          })}
                        />
                      </div>
                    </>
                  )}

                  {!item.joinerEmailRequired && (
                    <div className="text-sm text-gray-shuttle-soft">
                      <T
                        keyName={`components.joinSteps.${item.key}.subtitle`}
                        params={{
                          owner: () => data.service.owner.firstName,
                          service: () => data.service.provider.name,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-end items-center gap-4 max-md:flex-col max-md:justify-center md:mt-auto">
            <h6 className="text-xs text-gray-shuttle-soft max-md:text-base">
              <T keyName="components.joinSubscription.steps.success.btnDesc" />
            </h6>
            <Button
              type="submit"
              disabled={
                formik.isSubmitting ||
                (!formik.isValid && !data.service.provider.joinerEmailRequired)
              }
            >
              <T keyName="components.joinSubscription.steps.success.btn" />
            </Button>
          </div>
        </form>
      </>
    );
  }

  return (
    <SubscriptionPopupContainer
      type="join"
      providerId={id}
      owner={data?.service.owner}
    >
      {content}
    </SubscriptionPopupContainer>
  );
};

import React from "react";
import {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
} from "@/assets/images";
import { ImgProfile } from "@/components/ImgProfile";
import { Reload } from "@/assets/icons";

const avatars = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6];

export const AvatarPicker: React.FC<{
  avatar: string;
  setAvatar: (avatar: string) => void;
  className?: string;
}> = ({ avatar, setAvatar, className }) => {
  const selectRandomAvatar = () => {
    let randomIndex = Math.floor(Math.random() * avatars.length);
    while (avatar === avatars[randomIndex]) {
      randomIndex = Math.floor(Math.random() * avatars.length);
    }

    setAvatar(avatars[randomIndex]);
  };

  return (
    <div className="relative max-w-fit">
      <ImgProfile className={className} img={avatar} />
      <div onClick={() => selectRandomAvatar()} className="reload">
        <Reload
          className="absolute bottom-[-10px] left-[calc(50%-12px)] m-auto
            text-center cursor-pointer -scale-x-100
            [&_circle]:transition-all [&_circle]:duration-300 [&_circle]:ease-out
            [&_circle]:hover:stroke-blue-dodger [&_circle]:hover:fill-blue-dodger
            [&_path]:transition-all [&_path]:duration-300 [&_path]:ease-out
            [&_path]:hover:fill-white"
        />
      </div>
    </div>
  );
};

import { T, useTranslate } from "@tolgee/react";
import { SubscriptionPopupContainer } from "./PopupContainer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/Inputs/Button";
import { Checkbox, FormControlLabel } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { SharePassword } from "@/assets/illustrations";
import { PasswordInput, TextInput } from "@/components/Inputs";
import { toastError } from "@/utils/toast";
import { useContext } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { gql } from "@/gql";
import invariant from "tiny-invariant";

const PROVIDER_DETAILS = gql(`
  query getProviderDetailsForOfferDetailsPopup($providerId: String!) {
    provider(where: { id: $providerId }) {
      id
      name
      type
      status
      price {
        id
        fullPrice
        commission
        offerReturn
      }
      website
      numberOfSlots
      joinerEmailRequired
    }
  }
`);

const CREATE_SERVICE = gql(`
  mutation offerService($data: ServiceOfferCreateInput!) {
    offer(data: $data) {
      id
      slots {
        status
      }
    }
  }
`);

export const OfferSubscriptionDetailsPopup: React.FC = () => {
  const { t } = useTranslate();

  const { currentUser } = useContext(AuthContext)!;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  if (!id) navigate("/overview/subscriptions");

  const { data, loading } = useQuery(PROVIDER_DETAILS, {
    variables: { providerId: id! },
  });

  const [createService] = useMutation(CREATE_SERVICE);

  const validationSchema = yup.object().shape({
    email: yup.string().when([], () => {
      if (!data?.provider.joinerEmailRequired) {
        return yup
          .string()
          .email(t("form.inputs.errors.validEmail"))
          .required("Please enter an email");
      } else {
        return yup.string().email(t("form.inputs.errors.validEmail"));
      }
    }),
    password: yup.string().when([], () => {
      if (!data?.provider.joinerEmailRequired) {
        return yup.string().required("Please enter a password");
      } else {
        return yup.string();
      }
    }),
    acceptedTnC: yup.boolean().oneOf([true]),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      acceptedTnC: false,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      createService({
        variables: {
          data: {
            providerId: id!,
            ownerId: currentUser!.id!,
            email: values.email,
            password: values.password,
          },
        },
      })
        .then((res) => {
          console.log(res);
          formikHelpers.setSubmitting(false);
          navigate("/overview/subscriptions", { state: "refetch" });
        })
        .catch((err) => {
          console.error(err);
          formikHelpers.setSubmitting(false);
          toastError(err?.message ?? (err || "An error occurred"));
        });
    },
  });

  let content;
  if (loading) content = <Spinner />;
  else {
    invariant(data, "Data should be resolved");

    content = (
      <>
        <h1 className="text-2xl font-medium mb-2">
          <T
            keyName="components.offerSubscription.steps.accountDetails.title"
            params={{
              providername: () => data.provider.name,
            }}
          />
        </h1>
        {!data.provider.joinerEmailRequired && (
          <h6 className="text-gray-shuttle-soft text-sm mb-8">
            <T keyName="components.offerSubscription.steps.accountDetails.subTitle" />
          </h6>
        )}

        <form onSubmit={formik.handleSubmit}>
          {!data.provider.joinerEmailRequired && (
            <>
              <div className="flex flex-col mb-8 gap-4">
                <TextInput
                  name="email"
                  formik={formik}
                  label={t("form.inputs.labels.accountEmail", {
                    provider: () => data.provider.name.split(" ")[0],
                  })}
                />
                <PasswordInput
                  name="password"
                  formik={formik}
                  label={t("form.inputs.labels.accountPassword", {
                    provider: () => data.provider.name.split(" ")[0],
                  })}
                />
              </div>

              <div className="flex mb-4 items-start gap-4">
                <img src={SharePassword} alt="" />
                <h6 className="text-xs text-gray-shuttle-soft max-md:text-sm">
                  <T
                    keyName="components.offerSubscription.steps.accountDetails.shareConditions"
                    params={{
                      providername: () => data.provider.name,
                      gotoservice: () => (
                        <a
                          href={data.provider.website ?? "#"}
                          className="text-blue-dodger"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <T keyName="components.offerSubscription.steps.accountDetails.goTo" />{" "}
                          {data.provider.name.split(" ")[0]}
                        </a>
                      ),
                    }}
                  />
                </h6>
              </div>
            </>
          )}
          <div className="mb-2 text-xs font-medium max-md:text-sm">
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptedTnC"
                  value={formik.values.acceptedTnC}
                  checked={formik.values.acceptedTnC}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label={
                <h6 className="text-xs font-bold max-md:text-sm">
                  <T keyName="components.offerSubscription.steps.accountDetails.termsPolicy" />
                </h6>
              }
            />
          </div>
          <h6 className="mb-4 text-xs text-gray-shuttle-soft max-md:text-sm">
            <T
              keyName="components.offerSubscription.steps.accountDetails.shareLegalInfo"
              params={{
                terms: () => (
                  <Link
                    className="text-blue-dodger"
                    to="/terms-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <T keyName="components.offerSubscription.steps.accountDetails.terms" />
                  </Link>
                ),
                policy: () => (
                  <Link
                    className="text-blue-dodger"
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <T keyName="components.offerSubscription.steps.accountDetails.policy" />
                  </Link>
                ),
              }}
            />
          </h6>
          <h6 className="mb-4 text-xs text-gray-shuttle-soft max-md:text-sm">
            <T
              keyName="components.offerSubscription.steps.accountDetails.stopSharing"
              params={{
                terminating: () => (
                  <T keyName="components.offerSubscription.steps.accountDetails.terminating" />
                ),
              }}
            />
          </h6>
          <div className="flex justify-end items-center gap-4 max-md:flex-col max-md:justify-center">
            <h6 className="text-xs text-gray-shuttle-soft max-md:text-sm">
              <T keyName="components.offerSubscription.steps.accountDetails.submitInfo" />
            </h6>
            <Button
              type="submit"
              disabled={
                formik.isSubmitting ||
                (!formik.isValid && !data.provider.joinerEmailRequired) ||
                !formik.values.acceptedTnC
              }
            >
              <T keyName="components.offerSubscription.steps.accountDetails.btn" />
            </Button>
          </div>
        </form>
      </>
    );
  }

  return (
    <SubscriptionPopupContainer
      type="offer"
      providerId={data?.provider.id}
      owner={{
        fullName: currentUser!.fullName,
        avatar: currentUser!.avatar,
      }}
    >
      {content}
    </SubscriptionPopupContainer>
  );
};

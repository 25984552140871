import { T } from "@tolgee/react";
import { Plus64 } from "@/assets/icons";
import { PopupSkeleton } from "@/components/Popup";
import { Button } from "@/components/Inputs";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toastError, toastSuccess } from "@/utils/toast";
import { gql } from "@/gql";
import invariant from "tiny-invariant";
import { getErrorMessage } from "@/utils/errors.ts";

const SERVICE_DETAILS = gql(`
  query serviceOfferReturn($serviceId: String!) {
    service(where: { id: $serviceId }) {
      provider {
        price {
          offerReturn
        }
      }
    }
  }
`);

const RESERVE_SLOT = gql(`
  mutation reserveSlot($serviceId: String!) {
    reserveSlot(serviceId: $serviceId) {
      id
    }
  }
`);

export const SlotReserve: React.FC = () => {
  const { id: serviceId } = useParams<{ id: string }>();
  invariant(serviceId, "Service ID should be provided by route");

  const navigate = useNavigate();

  const { data } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId },
  });

  const [reserveSlot] = useMutation(RESERVE_SLOT);

  const handleConfirm = async () => {
    try {
      await reserveSlot({ variables: { serviceId } });
      toastSuccess("Slot was reserved!");
      navigate(`/overview/subscriptions/offer/${serviceId}/overview`, {
        state: "refetch",
      });
    } catch (e: unknown) {
      console.error(e);
      toastError(getErrorMessage(e));
    }
  };

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-6">
          <div className="border border-dashed border-blue-dodger rounded p-4">
            <Plus64
              className="rounded-full shadow-[0_0_0_6px] shadow-blue-dodger/20
                [&_path]:stroke-white [&_path]:fill-blue-dodger"
            />
          </div>
          <h6 className="text-pink-brink text-xs">
            <T
              keyName="components.offerSubscription.offerDetails.overview.reserveUserPopup.monthlyPayment"
              params={{
                currency: () => "€",
                price: () =>
                  data?.service?.provider.price.offerReturn.toFixed(2) ??
                  "0.00",
              }}
            />
          </h6>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T keyName="components.offerSubscription.offerDetails.overview.reserveUserPopup.title" />
        </h3>

        <h6 className="text-sm text-gray-shuttle-soft mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.overview.reserveUserPopup.subTitle"
            params={{
              price: () =>
                data?.service?.provider.price.offerReturn.toFixed(2) ?? "0.00",
              currency: () => "€",
            }}
          />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.offerSubscription.offerDetails.overview.reserveUserPopup.cancel" />
          </Button>
          <Button onClick={handleConfirm}>
            <T keyName="components.offerSubscription.offerDetails.overview.reserveUserPopup.reserve" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  );
};

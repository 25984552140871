import { RouteObject } from "react-router-dom";
import { Payments as PaymentsIcon } from "@/assets/icons";
import { Payments } from "@/pages/overview/payments";
import { Wallet } from "@/pages/overview/payments/Wallet";
import { WalletTransactions } from "@/pages/overview/payments/WalletTransactions";
import { InviteFriends } from "@/components/CommonPopups/InviteFriendsPopup";
import { Reclaim } from "@/components/CommonPopups/Reclaim";
import { VerifyDocuments } from "@/pages/overview/payments/Wallet/popups/VerifyDocuments";
import { DocsOnHold } from "@/pages/overview/payments/Wallet/popups/DocsOnHold";
import { PaymentMethods } from "@/pages/overview/payments/PaymentMethods";
import { DefaultCard } from "@/pages/overview/payments/PaymentMethods/popups/DefaultCard";
import { NewCard } from "@/pages/overview/payments/PaymentMethods/popups/NewCard";
import { NewCardVerify } from "@/pages/overview/payments/PaymentMethods/popups/NewCardVerify";
import { BankDetails } from "@/pages/overview/payments/BankDetails";
import { DocumentsVerification } from "@/pages/overview/payments/BankDetails/popups/DocumentsVerification";
import { FirstTimeQuestions } from "@/pages/overview/payments/BankDetails/popups/FirstTimeQuestions";
import { Guidelines } from "@/pages/overview/payments/BankDetails/popups/Guidelines";
import { VerifyID } from "@/pages/overview/payments/BankDetails/popups/VerifyId";
import { UploadDocument } from "@/pages/overview/payments/BankDetails/popups/UploadDocument";
import { UploadSuccess } from "@/pages/overview/payments/BankDetails/popups/UploadSuccess";
import { ReclaimSuccess } from "@/pages/overview/payments/Wallet/popups/ReclaimSuccess";

export const paymentsRoutes: RouteObject[] = [
  {
    path: "payments",
    element: <Payments />,
    handle: {
      label: "navbar.links.payments",
      icon: PaymentsIcon,
    },
    children: [
      {
        path: "wallet",
        element: <Wallet />,
        children: [
          {
            path: "popup/invite-friends",
            element: <InviteFriends />,
          },
          {
            path: "popup/reclaim",
            element: <Reclaim />,
          },
          {
            path: "popup/reclaim/success",
            element: <ReclaimSuccess />,
          },
          {
            path: "popup/reclaim/verify-documents",
            element: <VerifyDocuments />,
          },
          {
            path: "popup/docsOnHold",
            element: <DocsOnHold />,
          },
        ],
      },
      {
        path: "payment-methods",
        element: <PaymentMethods />,
        children: [
          {
            path: "popup/new-card",
            children: [
              {
                index: true,
                element: <NewCard />,
              },
              {
                path: ":transactionId/success",
                element: <NewCardVerify />,
              },
              {
                path: ":transactionId/error",
                element: <NewCardVerify />,
              },
            ],
          },
          {
            path: "popup/default-card/:cardId",
            element: <DefaultCard />,
          },
        ],
      },
      {
        path: "transactions",
        element: <WalletTransactions />,
        children: [
          {
            path: "popup/invite-friends",
            element: <InviteFriends />,
          },
        ],
      },
      {
        path: "bank-details",
        element: <BankDetails />,
        children: [
          {
            path: "documents-verification",
            children: [
              {
                index: true,
                element: <DocumentsVerification />,
              },
              {
                path: "questions",
                element: <FirstTimeQuestions />,
              },
              {
                path: "guidelines",
                element: <Guidelines />,
              },
              {
                path: "verify-id",
                element: <VerifyID />,
              },
              {
                path: "upload",
                element: <UploadDocument />,
              },
              {
                path: "success",
                element: <UploadSuccess />,
              },
            ],
          },
        ],
      },
    ],
  },
];

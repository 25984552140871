import { T, useTranslate } from "@tolgee/react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, TextInput } from "@/components/Inputs";
import { PasswordInput } from "@/components/Inputs";
import { PopupSkeleton } from "@/components/Popup/PopupSkeleton";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@/components/Spinner";
import { useContext, useState } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { SocialLogIn } from "@/components/SocialLogin";

export const Login: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("form.inputs.errors.validEmail"))
      .required(t("form.inputs.errors.email")),
    password: yup.string().required(t("form.inputs.errors.password")).min(8),
  });

  const authCtx = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      authCtx
        ?.logIn(values)
        .then(() => {
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false);
          navigate("/overview/subscriptions");
        })
        .catch((err) => {
          setError(err?.message ?? `${err}`);
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const canSubmit = !(formik.isSubmitting || !formik.isValid);

  const content = (
    <>
      <div className="flex items-baseline mb-6 max-md:flex-col">
        <div className="text-gray-100 text-2xl mr-2">
          <T keyName="components.signin.title" />
        </div>
        <div
          onClick={() => navigate("/auth/signup")}
          className="text-blue-dodger cursor-pointer text-sm"
        >
          <T keyName="components.signin.createAccount" />
        </div>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-4 items-center mt-8">
            <TextInput name="email" formik={formik} />
            <PasswordInput name="password" formik={formik} />

            <Button type="submit" disabled={!canSubmit} className="mt-4 w-fit">
              <T keyName="components.signin.button" />
            </Button>

            {error && (
              <div className="text-sm text-pink-brink text-center mt-3">
                <T keyName={error} defaultValue={error} />
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );

  return (
    <PopupSkeleton
      showClose={!formik.isSubmitting}
      onClose={() => navigate("/")}
    >
      <div className="w-[480px] h-full p-10 max-md:p-4 max-md:w-full">
        {formik.isSubmitting ? <Spinner /> : content}
      </div>
      <SocialLogIn type="signin" />
    </PopupSkeleton>
  );
};

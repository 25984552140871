import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { ChatContext } from "@/providers/Chat/context";

interface IUnreadCounterProps {
  serviceId?: string;
  serviceType?: "offer" | "join";
  className?: string;
}

export const UnreadCounter: React.FC<IUnreadCounterProps> = ({
  serviceId,
  serviceType,
  className,
}) => {
  const chatContext = useContext(ChatContext);
  if (!chatContext?.unreadCountPerService) return null;

  let count = 0;
  if (serviceId) {
    count = chatContext?.unreadCountPerService?.[serviceId];
  } else if (serviceType) {
    const serviceIds =
      serviceType === "offer"
        ? chatContext.servicesOwned
        : chatContext.servicesJoined;
    count = serviceIds
      .map((serviceId) => chatContext?.unreadCountPerService?.[serviceId])
      .reduce((sum, count) => sum + count, 0);
  } else {
    count = Object.values(chatContext.unreadCountPerService).reduce(
      (sum, count) => sum + count,
      0,
    );
  }

  if (!count) return null;

  return (
    <span
      className={twMerge(
        "rounded-full bg-pink-brink inline-flex items-center justify-center",
        "text-white font-bold text-xs p-2 w-2 h-2",
        className,
      )}
    >
      {count}
    </span>
  );
};

import { T, useTranslate } from "@tolgee/react";
import { PopupSkeleton } from "@/components/Popup";
import { Button } from "@/components/Inputs";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toastError } from "@/utils/toast";
import { ImgProfile } from "@/components/ImgProfile";
import { gql } from "@/gql";
import invariant from "tiny-invariant";
import { getErrorMessage } from "@/utils/errors.ts";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { TextareaAutosize } from "@mui/material";

const SLOT_DETAILS = gql(`
  query slotDetails($slotId: ID!) {
    slot(where: { id: $slotId }) {
      paymentDueDate
      service {
        provider {
          price {
            offerReturn
          }
        }
      }
      usedBy {
        fullName
        avatar
      }
    }
  }
`);

const BLOCK_USER = gql(`
  mutation blockUser($slotId: String!, $feedback: String!) {
    block(slotId: $slotId, feedback: $feedback) {
      id
    }
  }
`);

export const SlotUserBlock: React.FC = () => {
  const { t } = useTranslate();
  const { slotId } = useParams<{ slotId: string }>();
  invariant(slotId, "Slot ID should be provided by the router");

  const navigate = useNavigate();

  const { data } = useQuery(SLOT_DETAILS, {
    variables: { slotId },
  });

  const [blockUser] = useMutation(BLOCK_USER);

  const handleConfirm = async (feedback: string) => {
    try {
      await blockUser({ variables: { slotId, feedback } });
      navigate(`success`, {
        replace: true,
      });
    } catch (e: unknown) {
      console.error(e);
      toastError(getErrorMessage(e));
    }
  };

  return (
    <PopupSkeleton showClose>
      <Formik
        initialValues={{ feedback: "" }}
        validationSchema={yup.object({
          feedback: yup
            .string()
            .min(8, t("form.inputs.errors.feedback.length"))
            .required(t("form.inputs.errors.feedback.required")),
        })}
        onSubmit={({ feedback }) => handleConfirm(feedback)}
      >
        {(formik) => (
          <Form className="w-[450px] h-full max-md:w-full p-10 text-center">
            <div className="flex flex-col items-center gap-2 mb-6">
              <ImgProfile
                img={data?.slot?.usedBy?.avatar}
                className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50"
              />
              <h6 className="text-xs text-gray-shuttle">
                {data?.slot?.usedBy?.fullName}
              </h6>
              <h6 className="text-pink-brink text-xs">
                <T
                  keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.monthlyPayment"
                  params={{
                    currency: () => "€",
                    amount: () =>
                      data?.slot.service.provider.price.offerReturn.toFixed(
                        2,
                      ) ?? "0.00",
                    month: () => (
                      <T keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.month" />
                    ),
                  }}
                />
              </h6>
            </div>

            <h3 className="text-lg font-medium mb-4">
              <T
                keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.title"
                params={{
                  username: () => data?.slot?.usedBy?.fullName ?? "User",
                }}
              />
            </h3>

            <h6 className="text-sm text-gray-shuttle-soft mb-4">
              <T
                keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.subTitle"
                params={{
                  username: () => data?.slot?.usedBy?.fullName ?? "User",
                }}
              />
            </h6>

            <TextareaAutosize
              name="feedback"
              className="w-full border border-gray-shuttle rounded-md p-4 mt-2 resize-none"
              onChange={formik.handleChange}
              minRows={5}
              maxRows={5}
              placeholder={t("form.inputs.placeholders.feedback")}
            />

            <ErrorMessage
              name="feedback"
              render={(msg) => <span className="text-pink-brink">{msg}</span>}
            />

            <div className="flex justify-center gap-6 mt-8">
              <Button
                onClick={() => navigate(`../..`, { replace: true })}
                className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
              >
                <T keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.cancel" />
              </Button>
              <Button type="submit">
                <T keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.confirm" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PopupSkeleton>
  );
};

import { T } from "@tolgee/react";
import _ from "lodash";
import { EmptyTransactions } from "@/assets/illustrations";
import { PopupOutlet } from "@/components/Popup";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { Transactions } from "@/components/Transactions/Transactions";
import { gql } from "@/gql";
import { UserPaymentsTransactionsQuery } from "@/gql/graphql.ts";

const ALL_PAYMENTS = gql(`
  query userPaymentsTransactions(
    $userId: String!
    $skip: Float
    $after: TransactionWhereUniqueInput
    $first: Float!
  ) {
    payments: userGeneralTransactions(
      userId: $userId
      orderBy: { createdAt: desc }
      skip: $skip
      after: $after
      first: $first
    ) {
      id
      sender {
        id
        avatar
        fullName
      }
      receiver {
        id
        avatar
        fullName
      }
      amount
      type
      service {
        provider {
          name
        }
      }
      createdAt
      commission
      status
    }
  }
`);

export const WalletTransactions: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!;

  const { data, loading, fetchMore } = useQuery(ALL_PAYMENTS, {
    variables: {
      userId: currentUser!.id,
      first: 20,
    },
  });

  const [transactions, setTransactions] = useState<
    UserPaymentsTransactionsQuery["payments"]
  >([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async () => {
    const { data } = await fetchMore({
      variables: {
        after: {
          id: transactions[transactions.length - 1].id,
        },
        skip: 1,
      },
    });

    if (!data || _.isEmpty(data.payments)) {
      setHasMore(false);
      return;
    }

    const newTransactions = _.chain(transactions)
      .concat(data.payments)
      .sortedUniqBy("createdAt")
      .value();
    setTransactions(newTransactions);
  };

  useEffect(() => {
    if (data) {
      setTransactions(data.payments);
    }
  }, [data]);

  if (loading) return <Spinner />;

  let transactionsContainer;
  if (transactions.length > 0) {
    transactionsContainer = (
      <Transactions
        loadMore={loadMore}
        hasMore={hasMore}
        transactions={transactions}
      />
    );
  } else {
    transactionsContainer = (
      <div className="mt-20 max-md:mt-0 text-center">
        <div className="mb-8 flex justify-center">
          <img src={EmptyTransactions} alt="" />
        </div>
        <div className="text-2xl text-gray-shuttle-soft mb-2">
          <T keyName="emptyStates.transactions.oops" />
        </div>
        <div className="text-lg text-gray-shuttle-soft mb-4">
          <T keyName="emptyStates.transactions.text" />
        </div>
        <Link
          to="popup/invite-friends"
          className="text-blue-dodger cursor-pointer"
        >
          <T keyName="emptyStates.transactions.invite" />
        </Link>
      </div>
    );
  }

  return (
    <div>
      {transactionsContainer}
      <PopupOutlet />
    </div>
  );
};

import { createContext } from "react";
import { SocialAuthProviders } from "@/gql/graphql.ts";
import type { User } from "@/providers/Auth/provider.tsx";

interface IAuthContext {
  currentUser?: User;
  setUser: () => void;
  signUp: (values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    avatar: string;
    referrerCode?: string;
    source: string;
  }) => Promise<void>;
  logIn: (values: { email: string; password: string }) => Promise<void>;
  socialLogIn: (values: {
    provider: SocialAuthProviders;
    token: string;
  }) => Promise<void>;
  logOut: () => void;
}
export const AuthContext = createContext<IAuthContext | null>(null);

import { T, useTranslate } from "@tolgee/react";
import { PopupSkeleton } from "@/components/Popup";
import { KycDetails } from "@/assets/illustrations";
import { Button } from "@/components/Inputs/Button";
import { useFormik } from "formik";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toastError } from "@/utils/toast";
import { useNavigate } from "react-router-dom";
import { gql } from "@/gql";
import { getErrorMessage } from "@/utils/errors.ts";

const GET_COUNTRIES = gql(`
  query countries {
    countries {
      code
      label
    }
  }
`);

const UPDATE_USER = gql(`
  mutation updateUserKyc($nationality: String!, $dateOfBirth: DateTime!) {
    user: updateUser(
      data: { dateOfBirth: $dateOfBirth, nationality: $nationality }
    ) {
      id
      nationality
      dateOfBirth
    }
  }
`);

export const FirstTimeQuestions: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { data } = useQuery(GET_COUNTRIES);
  const [updateUser] = useMutation(UPDATE_USER);

  const formik = useFormik({
    initialValues: {
      nationality: "DEU",
      birthDate: moment().subtract(18, "years"),
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await updateUser({
          variables: {
            nationality: values.nationality,
            dateOfBirth: values.birthDate.toISOString(),
          },
        });
        formikHelpers.setSubmitting(false);
        navigate("../guidelines", { replace: true });
      } catch (error: unknown) {
        console.error(error);
        formikHelpers.setSubmitting(false);
        toastError(getErrorMessage(error));
      }
    },
  });

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-6">
          <img src={KycDetails} alt="" />
        </div>

        <h3 className="text-2xl font-medium mb-4">
          <T keyName="components.payments.bankDetails.documentsVerification.questions.title" />
        </h3>

        <p className="text-gray-shuttle-soft mb-8">
          <T keyName="components.payments.bankDetails.documentsVerification.questions.subTitle" />
        </p>

        <form
          className="flex flex-col justify-center mb-4 gap-4 text-left text-sm"
          onSubmit={formik.handleSubmit}
        >
          <FormControl fullWidth>
            <InputLabel id="label-nationality">
              <T keyName="components.payments.bankDetails.documentsVerification.questions.nationality" />
            </InputLabel>
            <Select
              name="nationality"
              labelId="label-nationality"
              label={t(
                "components.payments.bankDetails.documentsVerification.questions.nationality",
              )}
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            >
              {data?.countries.map(
                (country: { code: string; label: string }) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>

          <DatePicker
            name="birthDate"
            label={t(
              "components.payments.bankDetails.documentsVerification.questions.birthdate",
            )}
            value={formik.values.birthDate}
            onChange={(date) => formik.setFieldValue("birthDate", date)}
            format="DD/MM/YYYY"
          />

          <div className="flex justify-center gap-6 mt-4">
            <Button
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <T keyName="components.payments.bankDetails.documentsVerification.questions.btn" />
            </Button>
          </div>
        </form>
      </div>
    </PopupSkeleton>
  );
};

import { T } from "@tolgee/react";
import { PopupSkeleton } from "@/components/Popup";
import { Button } from "@/components/Inputs";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ImgProfile } from "@/components/ImgProfile";
import { gql } from "@/gql";
import invariant from "tiny-invariant";

const SLOT_DETAILS = gql(`
  query slotDetails($slotId: ID!) {
    slot(where: { id: $slotId }) {
      paymentDueDate
      service {
        provider {
          price {
            offerReturn
          }
        }
      }
      usedBy {
        fullName
        avatar
      }
    }
  }
`);

export const SlotUserBlockSuccess: React.FC = () => {
  const { slotId } = useParams<{ slotId: string }>();
  invariant(slotId, "Slot ID should be provided by route");

  const navigate = useNavigate();

  const { data } = useQuery(SLOT_DETAILS, {
    variables: { slotId },
  });

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-6">
          <ImgProfile
            img={data?.slot?.usedBy?.avatar}
            className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50 opacity-50"
          />
          <h6 className="text-xs text-gray-shuttle">
            {data?.slot?.usedBy?.fullName}
          </h6>
          <h6 className="text-pink-brink text-xs">
            <T
              keyName="components.offerSubscription.offerDetails.overview.blockUserSuccessPopup.monthlyPayment"
              params={{
                currency: () => "€",
                amount: () =>
                  data?.slot.service.provider.price.offerReturn.toFixed(2) ??
                  "0.00",
                month: () => (
                  <T keyName="components.offerSubscription.offerDetails.overview.blockUserSuccessPopup.month" />
                ),
              }}
            />
          </h6>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.overview.blockUserSuccessPopup.title"
            params={{
              username: () => data?.slot?.usedBy?.fullName ?? "User",
            }}
          />
        </h3>

        <h6 className="text-sm text-gray-shuttle-soft mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.overview.blockUserSuccessPopup.subTitle"
            params={{
              username: () => data?.slot?.usedBy?.fullName ?? "User",
            }}
          />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() =>
              navigate(`../..`, { replace: true, state: "refetch" })
            }
          >
            <T keyName="components.offerSubscription.offerDetails.overview.blockUserSuccessPopup.btn" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  );
};

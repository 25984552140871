import { useQuery } from "@apollo/client";
import { Link, useLocation, useParams } from "react-router-dom";
import { Spinner } from "@/components/Spinner";
import { T } from "@tolgee/react";
import { twJoin } from "tailwind-merge";
import {
  EmptyRequests,
  OfferEmptyWallet,
  OfferWallet,
  PendingRequests,
} from "@/assets/illustrations";
import { PopupOutlet } from "@/components/Popup";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { Slots } from "./Slots";
import { gql } from "@/gql";
import { RequestStatus } from "@/gql/graphql.ts";
import invariant from "tiny-invariant";

const SERVICE_OVERVIEW = gql(`
  query getServiceOverview($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      numFreeSlots
      provider {
        name
        numberOfSlots
      }
      requests {
        status
      }
    }
  }
`);

export const OfferOverview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useContext(AuthContext)!;

  const { data, loading, refetch } = useQuery(SERVICE_OVERVIEW, {
    variables: { serviceId: id! },
  });
  const { state } = useLocation();

  useEffect(() => {
    if (state === "refetch") refetch();
  }, [refetch, state]);

  if (loading) return <Spinner />;

  invariant(data, "Data should be loaded");
  const { service } = data;
  const numPending =
    service?.requests.filter(({ status }) => status === RequestStatus.Pending)
      .length ?? 0;

  return (
    <>
      <h3 className="text-lg font-medium mb-2">
        <T
          keyName={`components.offerSubscription.offerDetails.overview.${
            service.numFreeSlots === service.provider.numberOfSlots
              ? "emptyTitle"
              : "title"
          }`}
          params={{
            service: () => service.provider.name,
          }}
        />
      </h3>
      <h5 className="text-gray-shuttle-soft mb-8">
        <T
          keyName={`components.offerSubscription.offerDetails.overview.${
            service.numFreeSlots > 0 &&
            service.numFreeSlots < service.provider.numberOfSlots
              ? "info"
              : "emptyInfo"
          }`}
          params={{
            count: () => (
              <span
                className={twJoin(
                  "p-1 rounded-full text-white text-sm",
                  service.numFreeSlots === 0
                    ? "bg-pink-brink"
                    : "bg-blue-dodger",
                )}
              >
                <span className="p-1">{service.numFreeSlots}</span>
              </span>
            ),
            requests: () => (
              <span>
                <T keyName="components.offerSubscription.offerDetails.overview.pending" />
              </span>
            ),
            invite: () => (
              <span>
                <T keyName="components.offerSubscription.offerDetails.overview.invite" />
              </span>
            ),
          }}
        />
      </h5>

      <div className="w-full mb-14 max-md:mb-8">
        <Slots />
      </div>

      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6">
        <div className="flex justify-between items-center bg-orange-light px-4 py-6 rounded">
          <div>
            <p className="text-sm">
              <T
                keyName="components.offerSubscription.offerDetails.overview.pendingRequests.text"
                params={{
                  requests: () => (
                    <span
                      className={twJoin(
                        "p-1 rounded-full text-white text-sm",
                        numPending === 0 ? "bg-pink-brink" : "bg-blue-dodger",
                      )}
                    >
                      <span className={twJoin(numPending < 10 && "p-1")}>
                        {numPending}
                      </span>
                    </span>
                  ),
                }}
              />
            </p>
            <Link
              to={numPending === 0 ? "popup/invite-friends" : "../requests"}
              className="text-sm text-blue-dodger"
            >
              <T
                keyName={`components.offerSubscription.offerDetails.overview.pendingRequests.${
                  numPending === 0 ? "noRequests" : "viewRequests"
                }`}
              />
            </Link>
          </div>
          <img
            src={numPending > 0 ? PendingRequests : EmptyRequests}
            alt=""
            className="w-[76px] h-[76px]"
          />
        </div>

        <div className="flex justify-between items-center bg-blue-dodger/20 px-4 py-6 rounded">
          <div>
            <h5 className="font-medium mb-2">
              <T
                keyName={`components.offerSubscription.offerDetails.overview.wallet.${
                  currentUser!.totalBalance > 0 ? "congrats" : "emptyTitle"
                }`}
              />
            </h5>
            <p className="text-sm mb-2">
              <T
                keyName={`components.offerSubscription.offerDetails.overview.wallet.${
                  currentUser!.totalBalance > 0 ? "moneyWallet" : "noMoney"
                }`}
              />{" "}
              <br className="block md:hidden" />
              <span className="font-medium md:ml-2 text-lg">{`€ ${
                currentUser?.totalBalance.toFixed(2) ?? "0.00"
              }`}</span>
            </p>
            <Link
              to={
                currentUser!.totalBalance > 0
                  ? "popup/reclaim-money"
                  : "popup/invite-friends"
              }
              className="text-sm text-blue-dodger"
            >
              <T
                keyName={`components.offerSubscription.offerDetails.overview.wallet.${
                  currentUser!.totalBalance > 0 ? "reclaim" : "changeThings"
                }`}
              />
            </Link>
          </div>
          <img
            src={currentUser!.totalBalance > 0 ? OfferWallet : OfferEmptyWallet}
            alt=""
            className="w-[76px] h-[76px]"
          />
        </div>
      </div>
      <PopupOutlet />
    </>
  );
};

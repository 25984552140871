import { useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { CardGeneric, CartesBancaires, Mastercard, Visa } from "@/assets/icons";
import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { useLocation, useNavigate } from "react-router-dom";
import { DefaultCard } from "@/assets/illustrations";
import { LinkButton } from "@/components/Inputs/Button";
import { twJoin } from "tailwind-merge";
import { PopupOutlet } from "@/components/Popup";
import { gql } from "@/gql";

const GET_CARDS = gql(`
  query getCards {
    me {
      cards {
        id
        maskedNumber
        expirationDate
        type
        country
        isDefault
      }
    }
  }
`);

export const PaymentMethods: React.FC = () => {
  const { data, loading, refetch } = useQuery(GET_CARDS);
  const [cards, setCards] = useState<
    {
      id: number;
      maskedNumber: string;
      expirationDate: string;
      type: string;
      country: string;
      isDefault: boolean;
      cardImage: JSX.Element;
    }[]
  >([]);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state === "refetch") refetch();
  }, [refetch, state]);

  useEffect(() => {
    if (!data?.me?.cards) return;
    setCards(
      data.me.cards.map((card) => {
        let expDate = card.expirationDate;
        expDate = expDate!.slice(5, 8) + "/" + expDate!.slice(2, 4);

        let maskedNum: string = card.maskedNumber;
        maskedNum = maskedNum.replaceAll("#", "*");
        maskedNum =
          maskedNum.slice(0, 4) +
          "    " +
          maskedNum.slice(4, 8) +
          "    " +
          maskedNum.slice(8, 12) +
          "    " +
          maskedNum.slice(12, 16);

        let cardImage = <CardGeneric />;
        switch (card.type) {
          case "VISA":
            cardImage = <Visa />;
            break;
          case "MASTERCARD":
            cardImage = <Mastercard />;
            break;
          case "CB":
            cardImage = <CartesBancaires />;
            break;
        }

        return {
          ...card,
          maskedNumber: maskedNum,
          expirationDate: expDate,
          cardImage,
        };
      }),
    );
  }, [data]);

  if (loading) return <Spinner />;

  return (
    <div>
      <h1 className="text-lg mb-1">
        <T keyName="components.payments.paymentMethods.title" />
      </h1>
      <h6 className="text-gray-shuttle mb-8">
        <T keyName="components.payments.paymentMethods.subTitle" />
      </h6>

      {cards.length > 0 && (
        <div className="flex flex-col mt-8 mb-10 gap-4 justify-center max-md:items-center">
          {cards.map((card) => (
            <div
              className={twJoin(
                "flex justify-center items-center relative w-[380px] py-4 gap-6 border rounded-2xl max-md:w-[320px]",
                card.isDefault
                  ? "border-black bg-titan"
                  : "border-bombay bg-iron/20 hover:border-gray-shuttle-soft hover:bg-titan/50",
              )}
              onClick={
                !card.isDefault
                  ? () => {
                      navigate(
                        `popup/default-card/${card.id}?num=${card.maskedNumber}&exp=${card.expirationDate}&type=${card.type}`,
                      );
                    }
                  : undefined
              }
              key={card.id}
            >
              <div className="h-7 [&_svg]:h-7 [&_svg]:w-[42px]">
                {card.cardImage}
              </div>
              <div>{card.maskedNumber}</div>
              <div>{card.expirationDate}</div>
              {card.isDefault && (
                <>
                  <div className="flex items-center absolute z-50 -left-6 max-md:-left-4">
                    <img
                      src={DefaultCard}
                      alt="default-card"
                      className="w-12 max-md:w-9"
                    />
                  </div>
                  <div className="absolute left-[calc(100%+24px)] text-blue-dodger max-md:hidden">
                    <T keyName="components.payments.wallet.paymentMethods.defaultCard" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="flex mt-6 mb-12 max-md:justify-center">
        <LinkButton
          to="popup/new-card"
          className="flex justify-center items-center"
        >
          <div className="flex mr-5 [&_svg]:h-7 [&_svg]:w-[42px]">
            <Visa />
            <Mastercard />
          </div>
          <T keyName="components.payments.wallet.paymentMethods.addNewCardBtn" />
        </LinkButton>
      </div>
      <PopupOutlet />
    </div>
  );
};

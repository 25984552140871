import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { SendMessage as SendMessageIcon } from "@/assets/icons";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "@/providers/Auth/context";
import { Spinner } from "@/components/Spinner";
import { formatMessages } from "./utils";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import { useScreenDetector } from "@/hooks/ScreenDetector";
import { ChatContext, ChatMessage } from "@/providers/Chat/context";
import { gql } from "@/gql";
import "./Chat.css";
import invariant from "tiny-invariant";

const GET_ALL_CHAT_MESSAGES = gql(`
  query serviceChatMessages(
    $serviceId: String!
    $skip: Float
    $after: String
    $first: Float!
  ) {
    chatMessages: getChatMessages(
      serviceId: $serviceId
      skip: $skip
      after: $after
      take: $first
    ) {
      id
      content
      createdAt
      userId
      user {
        firstName
        fullName
        avatar
      }
    }
  }
`);

const CREATE_CHAT_MESSAGE = gql(`
  mutation sendMessage($serviceId: String!, $content: String!) {
    chatMessage: createChatMessage(serviceId: $serviceId, content: $content) {
      id
      content
      userId
      createdAt
      __typename
    }
  }
`);

const MARK_CHAT_MESSAGE_READ = gql(`
  mutation markChatRead($chatMessageId: String!, $serviceId: String!) {
    markRead: markChatMessageRead(
      chatMessageId: $chatMessageId
      serviceId: $serviceId
    )
  }
`);

export const Chat: React.FC = () => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenDetector();

  return (
    <div
      className="chat-container"
      ref={chatContainerRef}
      style={{
        height: `${
          window.innerHeight -
          (chatContainerRef?.current?.getBoundingClientRect().top ?? 200) -
          (isMobile ? -20 : 32)
        }px`,
      }}
    >
      <ChatMessages />
      <SendMessage />
    </div>
  );
};

const ChatMessages: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!;
  const { id: serviceId } = useParams<{ id: string }>();

  invariant(serviceId, "Service ID should be provided by route");

  const { loading, data, fetchMore } = useQuery(GET_ALL_CHAT_MESSAGES, {
    variables: { serviceId, first: 5 },
    fetchPolicy: "network-only",
  });
  const [markChatMessageRead] = useMutation(MARK_CHAT_MESSAGE_READ);
  const chatContext = useContext(ChatContext);

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isPageActive, setIsPageActive] = useState<boolean>(true);

  const chatMessagesContainerRef = useRef<HTMLDivElement>(null);
  const prevChatMessagesRef = useRef<ChatMessage[]>([]);
  const markReadTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onVisibilityChange = () => {
    const active = document.visibilityState === "visible";
    setIsPageActive(active);
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  useEffect(() => {
    const whatsappWidget = document.getElementById("whatsapp-chat-widget");
    if (!whatsappWidget) return;
    const originalDisplay = whatsappWidget.style.display;
    whatsappWidget.style.display = "none";
    return () => {
      whatsappWidget.style.display = originalDisplay;
    };
  }, []);

  useEffect(() => {
    if (!isPageActive) return;
    if (_.isEmpty(chatMessages)) return;
    if (chatContext?.unreadCountPerService?.[serviceId!] === 0) return;
    markChatMessageRead({
      variables: { serviceId, chatMessageId: _.last(chatMessages)!.id },
    });
    if (chatContext) chatContext.refetchUnreadCount();
    return () => {
      if (markReadTimeoutRef.current) {
        clearTimeout(markReadTimeoutRef.current);
        markReadTimeoutRef.current = null;
      }
    };
  }, [chatContext, chatMessages, isPageActive, markChatMessageRead, serviceId]);

  useEffect(() => {
    if (!chatContext?.subscribeToNew) return;
    chatContext.subscribeToNew(serviceId!, (_serviceId, message) => {
      if (_serviceId !== serviceId) return;
      setChatMessages((prev) => [...prev, message]);
    });
  }, [serviceId, chatContext]);

  useEffect(() => {
    if (!data) return;
    setChatMessages(data.chatMessages.toReversed());
  }, [data]);

  useEffect(() => {
    if (_.isEmpty(chatMessages)) return;
    if (!chatMessagesContainerRef.current) return;
    if (_.last(chatMessages)?.id !== _.last(prevChatMessagesRef.current)?.id) {
      chatMessagesContainerRef.current.scrollTo({
        top: chatMessagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    prevChatMessagesRef.current = chatMessages;
  }, [chatMessages]);

  if (loading)
    return (
      <div className="chat-loader">
        <Spinner />
      </div>
    );

  if (_.isEmpty(chatMessages)) return <h1>No messages</h1>;

  return (
    <div className="chat-messages-container" ref={chatMessagesContainerRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={async () => {
          const { data } = await fetchMore({
            variables: { after: chatMessages[0].id, skip: 1, first: 20 },
          });
          if (!data || _.isEmpty(data.chatMessages)) {
            setHasMore(false);
            return;
          }

          setChatMessages([...data.chatMessages.toReversed(), ...chatMessages]);
        }}
        loader={
          <div className="chat-loader" key={0}>
            <Spinner />
          </div>
        }
        hasMore={hasMore}
        className="chat-scroller"
        useWindow={false}
        initialLoad
        isReverse
      >
        {formatMessages(chatMessages, currentUser!.id)}
      </InfiniteScroll>
    </div>
  );
};

const SendMessage: React.FC = () => {
  const [content, setContent] = useState("");
  const { id } = useParams<{ id: string }>();

  const [createChatMessage] = useMutation(CREATE_CHAT_MESSAGE);

  const sendMessage = () => {
    if (!content) return;
    createChatMessage({
      variables: {
        content,
        serviceId: id!,
      },
    });
    setContent("");
  };

  return (
    <div className="chat-inputarea">
      <input
        type="text"
        className="chat-input"
        placeholder="Type your message here..."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            sendMessage();
          }
        }}
      />
      <button
        type="submit"
        className="chat-send-btn"
        onClick={() => sendMessage()}
      >
        <SendMessageIcon />
      </button>
    </div>
  );
};

import { T } from "@tolgee/react";
import {
  EmptyWallet,
  ReclaimMoney,
  ReclaimedMoney,
} from "@/assets/illustrations";
import { Button } from "@/components/Inputs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PopupOutlet } from "@/components/Popup";
import { useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { gql } from "@/gql";

const WALLET_DETAILS = gql(`
  query getUserWalletDetails {
    me {
      totalBalance
      balanceTransfered
    }
  }
`);

export const Wallet: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!;

  const { data, loading, refetch } = useQuery(WALLET_DETAILS);

  const { state } = useLocation();
  useEffect(() => {
    if (state === "refetch") refetch();
  }, [refetch, state]);

  const navigate = useNavigate();

  if (loading) return <Spinner />;

  const totalBalance = data?.me?.totalBalance || 0;
  const transferredBalance = data?.me?.balanceTransfered || 0;

  const handleReclaimMoneyPopup = () => {
    const route =
      currentUser?.idDocumentsStatus === "PENDING_VERIFICATION" ||
      currentUser?.ibanStatus === "PENDING_VERIFICATION"
        ? "popup/docsOnHold"
        : currentUser?.idDocumentsStatus === "ACCEPTED" &&
            currentUser?.ibanStatus === "ACCEPTED"
          ? "popup/reclaim"
          : "popup/reclaim/verify-documents";
    navigate(route);
  };

  return (
    <div>
      <h1 className="text-lg mb-1 font-medium">
        <T keyName="components.payments.wallet.title" />
      </h1>
      <h6 className="text-gray-shuttle mb-8">
        <T keyName="components.payments.wallet.subTitle" /> 30 days ago
      </h6>

      <div className="flex max-md:flex-col w-full gap-6 text-center">
        <div className="w-full flex flex-col justify-center items-center gap-4 border border-dashed border-gray-shuttle py-8 px-6">
          <div className="flex flex-col gap-4">
            <div className="flex justify-center">
              <img src={totalBalance > 0 ? ReclaimMoney : EmptyWallet} alt="" />
            </div>
            {totalBalance > 0 ? (
              <>
                <div className="text-2xl font-medium">
                  <T keyName="currency.symbol.euro" />
                  {totalBalance.toFixed(2)}
                </div>
                <div className="text-xl">
                  <T keyName="components.payments.wallet.available.title" />
                </div>
                <div className="text-xs text-gray-shuttle">
                  <T keyName="components.payments.wallet.available.info" />
                </div>
                <div className="reclaim-btn">
                  <Button onClick={handleReclaimMoneyPopup}>
                    <T keyName="components.payments.wallet.available.btn" />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h1 className="text-gray-shuttle text-2xl font-medium">
                  <T keyName="emptyStates.wallet.oops" />
                </h1>
                <div className="text-gray-shuttle">
                  <h6>
                    <T keyName="emptyStates.wallet.first" />
                  </h6>
                  <h6>
                    <T keyName="emptyStates.wallet.second" />
                  </h6>
                </div>
                <Link
                  to="popup/invite-friends"
                  className="text-blue-dodger text-sm cursor-pointer"
                >
                  <T keyName="emptyStates.wallet.invite" />
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="bg-titan flex flex-col gap-4 w-full justify-center items-center py-8 px-6">
          <div className="img">
            <img src={ReclaimedMoney} alt="" />
          </div>
          <h3 className="text-lg px-12">
            <T keyName="components.payments.wallet.reclaimed.text" />
          </h3>
          <h2 className="text-2xl font-medium">{`€${transferredBalance}`}</h2>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-sm text-gray-shuttle">
          <T
            keyName="components.payments.wallet.verification"
            params={{
              bankdetails: (
                <Link
                  to="../bank-details"
                  className="text-blue-dodger cursor-pointer"
                >
                  <T keyName="components.payments.wallet.link" />
                </Link>
              ),
            }}
          />
        </p>
      </div>
      <PopupOutlet />
    </div>
  );
};

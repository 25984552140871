import { TextField, TextFieldProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";

export const TextInput: React.FC<
  {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any;
    intlKey?: string;
  } & TextFieldProps
> = ({ name, formik, intlKey, label, ...extraProps }) => {
  const { t } = useTranslate();

  return (
    <TextField
      name={name}
      label={label ? label : t(`form.inputs.labels.${intlKey ?? name}`)}
      placeholder={t(`form.inputs.placeholders.${intlKey ?? name}`)}
      fullWidth
      required
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      {...extraProps}
    />
  );
};

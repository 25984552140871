import { T, useTranslate } from "@tolgee/react";
import { ServiceSlotProps, ServiceSlot } from "./ServiceSlot";
import { twJoin } from "tailwind-merge";
import {
  JoinEmptyState,
  JoinService,
  OfferEmptyState,
  OfferService,
} from "@/assets/illustrations";
import { LinkButton } from "@/components/Inputs/Button";
import { UnreadCounter } from "@/components/UnreadCounter";

export const ServicesGrid: React.FC<{
  type: "join" | "offer";
  services: ServiceSlotProps[];
}> = ({ type, services }) => {
  const { t } = useTranslate();
  const intlType = type === "join" ? "Join" : "Offer";

  return (
    <div
      className={twJoin(
        "flex flex-col justify-center p-4 md:py-20",
        type === "join" ? "bg-porcelain max-md:bg-inherit" : "",
      )}
    >
      <div
        className={twJoin(
          "md:mx-auto md:max-w-[340px]",
          services.length > 0 ? "" : "items-center text-center",
        )}
      >
        <div
          className={twJoin(
            "flex mb-8",
            services.length === 0 && "justify-center max-md:mt-4",
          )}
        >
          <img
            src={
              type === "join"
                ? services.length === 0
                  ? JoinEmptyState
                  : JoinService
                : services.length === 0
                  ? OfferEmptyState
                  : OfferService
            }
            alt=""
            className="max-md:w-[80%]"
          />
        </div>
        <h1
          className={twJoin(
            "text-2xl mb-6 max-md:text-3xl",
            services.length > 0 && "max-md:hidden",
          )}
        >
          <T keyName={`emptyStates.no${intlType}Services.title`} />
        </h1>
        <h6
          className={twJoin(
            "text-gray-shuttle mb-8",
            services.length > 0
              ? "text-sm max-md:text-base"
              : "px-6 max-md:text-lg",
          )}
        >
          <T keyName={`emptyStates.no${intlType}Services.subTitle`} />
        </h6>
        {services.length === 0 ? (
          <LinkButton to={`new-${type}`}>
            <T keyName={`emptyStates.no${intlType}Services.btn`} />
          </LinkButton>
        ) : (
          <div className="grid grid-cols-2 gap-5 mr-auto">
            <div className="md:w-40">
              <ServiceSlot
                text={t(`components.${type}Subscription.newService.text`)}
                id="new"
                info=""
                img=""
                linkTo={`/overview/subscriptions/new-${type}`}
              />
            </div>
            {services.map((service) => (
              <div className="relative md:w-40" key={service.id}>
                <UnreadCounter
                  serviceType={type}
                  serviceId={service.id}
                  className="absolute top-2 right-5 text-base p-3 w-3 h-3 max-md:right-3"
                />
                <ServiceSlot
                  {...service}
                  linkTo={`/overview/subscriptions/${type}/${service.id}`}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

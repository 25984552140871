import { T } from "@tolgee/react";
import { Error, Heart } from "@/assets/illustrations";
import { PopupSkeleton } from "@/components/Popup/PopupSkeleton";
import { Button } from "@/components/Inputs";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { client } from "@/apollo";
import { gql } from "@/gql";
import { getErrorMessage } from "@/utils/errors.ts";

const DELETE_USER_ACCOUNT = gql(`
  mutation deleteUser {
    deleteUser {
      id
    }
  }
`);

export const RemoveAccount: React.FC = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [deleteUser] = useMutation(DELETE_USER_ACCOUNT);

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <img src={!error ? Heart : Error} alt="" />
        </div>

        <h1 className="text-lg font-medium mb-4">
          <T
            keyName={
              !error
                ? "components.settings.removeAccount.title"
                : "components.settings.removeAccountError.title"
            }
          />
        </h1>
        <h6 className="text-sm text-gray-shuttle mb-4">
          {!error ? (
            <T keyName="components.settings.removeAccount.subTitle" />
          ) : (
            error
          )}
        </h6>
        <div className="flex justify-center gap-6 mt-8">
          {!error ? (
            <>
              <Button
                onClick={() => navigate(-1)}
                className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
              >
                <T keyName="components.settings.removeAccount.btnCancel" />
              </Button>
              <Button
                onClick={async () => {
                  try {
                    await deleteUser();
                    client.clearStore();
                    navigate("/auth/account-removed", { replace: true });
                  } catch (err: unknown) {
                    setError(getErrorMessage(err));
                  }
                }}
                className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
              >
                <T keyName="components.settings.removeAccount.btnConfirm" />
              </Button>
            </>
          ) : (
            <Button
              onClick={() => navigate(-1)}
              className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
            >
              <T keyName="components.settings.removeAccountError.btn" />
            </Button>
          )}
        </div>
      </div>
    </PopupSkeleton>
  );
};

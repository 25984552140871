import { T } from "@tolgee/react";
import { SubscriptionPopupContainer } from "./PopupContainer";
import { PickAService } from "@/assets/illustrations";

export const SubscriptionSelectPopup: React.FC<{ type: "join" | "offer" }> = ({
  type,
}) => {
  return (
    <SubscriptionPopupContainer type={type} showCloseButton={false}>
      <>
        <h1 className="text-2xl font-medium mb-16">
          <T
            keyName={`components.${type}Subscription.steps.pickAService.title`}
          />
        </h1>
        <div className="flex justify-center mb-6">
          <img src={PickAService} alt="" />
        </div>
        <h6 className="text-gray-shuttle-soft text-center">
          <T
            keyName={`components.${type}Subscription.steps.pickAService.info`}
          />
        </h6>
      </>
    </SubscriptionPopupContainer>
  );
};

import { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import { T, useTranslate } from "@tolgee/react";
import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useScreenDetector } from "@/hooks/ScreenDetector";
import {
  GroupImgJoin,
  GroupImgOffer,
} from "@/assets/landing_page/service_prices/illustrations";
import {
  Deezer,
  Disney,
  Netflix,
  Paramount,
  Youtube,
} from "@/assets/landing_page/service_prices/icons";
import { Button } from "@/components/Inputs/Button";
import {
  DeezerMobile,
  DisneyMobile,
  NetflixMobile,
  ParamountMobile,
  YoutubeMobile,
} from "@/assets/icons/brands";
import { useNavigate } from "react-router-dom";

/* Constants */
const JOIN_SERVICES = [
  {
    image: Youtube,
    imageMobile: YoutubeMobile,
    price: "€3.99",
    zIndex: 1,
    marginLeft: "0",
  },
  {
    image: Deezer,
    imageMobile: DeezerMobile,
    price: "€3.99",
    zIndex: 2,
    marginLeft: "-70px",
  },
  {
    image: Netflix,
    imageMobile: NetflixMobile,
    price: "€5.69",
    zIndex: 3,
    marginLeft: "-90px",
  },
  {
    image: Disney,
    imageMobile: DisneyMobile,
    price: "€3.44",
    zIndex: 2,
    marginLeft: "-90px",
  },
  {
    image: Paramount,
    imageMobile: ParamountMobile,
    price: "€3.99",
    zIndex: 1,
    marginLeft: "-70px",
  },
];

const OFFER_SERVICES = [
  {
    image: Youtube,
    imageMobile: YoutubeMobile,
    price: "€14.99",
    zIndex: 1,
    marginLeft: "0",
  },
  {
    image: Deezer,
    imageMobile: DeezerMobile,
    price: "€14.99",
    zIndex: 2,
    marginLeft: "-70px",
  },
  {
    image: Netflix,
    imageMobile: NetflixMobile,
    price: "€17.99",
    zIndex: 3,
    marginLeft: "-90px",
  },
  {
    image: Disney,
    imageMobile: DisneyMobile,
    price: "€6.99",
    zIndex: 2,
    marginLeft: "-90px",
  },
  {
    image: Paramount,
    imageMobile: ParamountMobile,
    price: "€14.99",
    zIndex: 1,
    marginLeft: "-70px",
  },
];

export const Subscriptions: React.FC = () => {
  const { isSmall, isBig } = useScreenDetector();
  const { t } = useTranslate();
  const [activeTab, setActiveTab] = useState<"join" | "offer">("join");
  const navigate = useNavigate();

  return (
    <div className="max-w-[795px] w-full flex flex-col mx-auto text-center max-md:overflow-hidden">
      {isBig && (
        <>
          <div
            className="relative mb-3 bg-blue-dodger flex justify-center rounded-lg
              before:block before:absolute before:top-[28px] before:left-[145px]
              before:w-[468px] before:h-[70px] before:z-[-1] before:rounded
              before:bg-transparent before:shadow-[0_2px_60px_0] before:shadow-black/20"
          >
            <Tab
              title={t("components.landingPage.subscriptions.join.title")}
              subTitle={t("components.landingPage.subscriptions.join.subTitle")}
              isActive={activeTab === "join"}
              onClick={() => setActiveTab("join")}
            />
            <Tab
              title={t("components.landingPage.subscriptions.offer.title")}
              subTitle={t(
                "components.landingPage.subscriptions.offer.subTitle",
              )}
              isActive={activeTab === "offer"}
              onClick={() => setActiveTab("offer")}
            />
          </div>
          <div>
            <TabContent tab={activeTab} />
          </div>
          <div className="flex justify-center mb-[70px]">
            <Button
              onClick={() => navigate("/auth/signup")}
              className="bg-blue-dodger text-white rounded py-4 px-8 w-max
              hover:bg-blue-royal hover:text-white active:bg-blue-royal focus:bg-blue-royal"
            >
              {t(`components.landingPage.subscriptions.${activeTab}.btn`)}
            </Button>
          </div>
        </>
      )}

      {isSmall && (
        <>
          <div className="mb-10">
            <MobileCarousel tab="join" />
          </div>
          <div>
            <MobileCarousel tab="offer" />
          </div>
        </>
      )}
    </div>
  );
};

const Tab: React.FC<{
  title: string;
  subTitle: string;
  isActive: boolean;
  onClick: () => void;
}> = ({ title, subTitle, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={twJoin(
        "max-w-[238px] w-full cursor-pointer",
        isActive && "border-b-2 border-white",
      )}
    >
      <div className="flex flex-col box-border">
        <h6
          className={twJoin(
            "leading-6 pt-6",
            isActive ? "text-white" : "text-zircon/50",
          )}
        >
          {title}
        </h6>
        <h4
          className={twJoin(
            "text-lg font-bold mb-6",
            isActive ? "text-white" : "text-zircon/50",
          )}
        >
          {subTitle}
        </h4>
      </div>
    </div>
  );
};

const ServiceCard: React.FC<{
  icon: string;
  text: string | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
}> = ({ icon, text, className, style }) => {
  return (
    <div className={twMerge("max-w-fit", className)} style={style}>
      <img src={icon} alt="" />
      <div className="mx-auto h-8 w-fit rounded-2xl bg-blue-dodger/20">
        <p className="text-center text-blue-dodger text-xs font-medium leading-5 px-3 py-1">
          {text}
        </p>
      </div>
    </div>
  );
};

const TabContent: React.FC<{ tab: "join" | "offer" }> = ({ tab }) => {
  return (
    <div className="w-full mx-auto">
      <div>
        <div className="mx-auto max-w-[421px]">
          <img
            src={tab === "join" ? GroupImgJoin : GroupImgOffer}
            alt="services"
          />
        </div>
        <div className="-my-40 mb-10 flex justify-start items-center">
          {tab === "join"
            ? JOIN_SERVICES.map(({ price, image, zIndex, marginLeft }, idx) => (
                <ServiceCard
                  key={idx}
                  style={{ zIndex, marginLeft }}
                  icon={image}
                  text={
                    <T
                      keyName="components.landingPage.subscriptions.join.paymonthly"
                      params={{
                        pay: () => (
                          <span className="font-bold text-sm">
                            {idx == 2 ? (
                              <T keyName="components.landingPage.subscriptions.join.payonly" />
                            ) : (
                              <T keyName="components.landingPage.subscriptions.join.pay" />
                            )}
                          </span>
                        ),
                        price: () => (
                          <span className="font-bold text-sm">{price}</span>
                        ),
                        month: () => (
                          <span>
                            {idx == 2 && (
                              <T keyName="components.landingPage.subscriptions.join.month" />
                            )}
                          </span>
                        ),
                      }}
                    />
                  }
                />
              ))
            : OFFER_SERVICES.map(
                ({ price, image, zIndex, marginLeft }, idx) => (
                  <ServiceCard
                    key={idx}
                    style={{ zIndex, marginLeft }}
                    icon={image}
                    text={
                      <T
                        keyName="components.landingPage.subscriptions.offer.paymontly"
                        params={{
                          earn: () => (
                            <span className="font-bold text-sm">
                              {idx == 2 ? (
                                <T keyName="components.landingPage.subscriptions.offer.earnupto" />
                              ) : (
                                <T keyName="components.landingPage.subscriptions.offer.earn" />
                              )}
                            </span>
                          ),
                          price: () => (
                            <span className="font-bold text-sm">{price}</span>
                          ),
                          month: () => (
                            <span>
                              {idx == 2 && (
                                <T keyName="components.landingPage.subscriptions.offer.month" />
                              )}
                            </span>
                          ),
                        }}
                      />
                    }
                  />
                ),
              )}
        </div>
      </div>
      <div className="max-w-[302px] h-20 w-full mx-auto mb-7">
        <h6 className="leading-7 text-gray">
          <p>
            {tab === "join" ? (
              <T
                keyName={`components.landingPage.subscriptions.join.info`}
                params={{
                  getmore: () => (
                    <span className="font-bold">
                      <T keyName="components.landingPage.subscriptions.join.getmore" />
                    </span>
                  ),
                }}
              />
            ) : (
              <T
                keyName={`components.landingPage.subscriptions.join.info`}
                params={{
                  getmore: () => (
                    <span className="font-bold">
                      <T keyName="components.landingPage.subscriptions.offer.forfree" />
                    </span>
                  ),
                }}
              />
            )}
          </p>
        </h6>
      </div>
    </div>
  );
};

const MobileServiceCard: React.FC<{
  icon: string;
  text: string | JSX.Element;
  className?: string;
}> = ({ icon, text, className }) => {
  return (
    <div className="max-w-24 w-full m-auto">
      <div>
        <div className="w-24 h-24 bg-white flex flex-col rounded-xl mb-2 shadow-[0_0_20px_0] shadow-black/10">
          <img src={icon} alt="service icon" className="m-auto" />
        </div>
        <div className="max-w-12 m-auto">
          <p className={twMerge("text-blue-dodger", className)}>{text}</p>
        </div>
      </div>
    </div>
  );
};

const MobileCarousel: React.FC<{ tab: "join" | "offer" }> = ({ tab }) => {
  const navigate = useNavigate();

  return (
    <div className="mb-36">
      <div className="m-auto">
        <div className="mx-4 mb-5">
          <h3
            className={twJoin(
              "text-2xl",
              tab === "join" ? "text-blue-dodger" : "text-zircon",
            )}
          >
            <T
              keyName={`components.landingPage.subscriptions.${tab}Mobile.firsttitle`}
            />
          </h3>
          <h3
            className={twJoin(
              "text-2xl font-bold",
              tab === "join" ? "text-blue-dodger" : "text-zircon",
            )}
          >
            <T
              keyName={`components.landingPage.subscriptions.${tab}Mobile.secondtitle`}
            />
          </h3>
        </div>
        <div className="mx-4 mb-5">
          <h5
            className={twJoin(
              "text-lg",
              tab === "join" ? "text-blue-dodger" : "text-zircon",
            )}
          >
            <T
              keyName={`components.landingPage.subscriptions.${tab}Mobile.text`}
              params={{
                getmore: () => (
                  <span className="font-bold">
                    <T keyName="components.landingPage.subscriptions.joinMobile.getmore" />
                  </span>
                ),
                forfree: () => (
                  <span className="font-bold">
                    <T keyName="components.landingPage.subscriptions.offerMobile.forfree" />
                  </span>
                ),
              }}
            />
          </h5>
        </div>
        <div className="mb-11">
          <div
            className={twJoin(
              "mb-6 text-lg leading-6 font-bold",
              tab === "join" ? "text-blue-dodger" : "text-zircon",
            )}
          >
            <T
              keyName={`components.landingPage.subscriptions.${tab}Mobile.payonly`}
            />
          </div>
          <div className="relative h-[148px]">
            <Carousel
              plugins={[
                "infinite",
                "centered",
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 3000,
                  },
                },
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 4,
                  },
                },
              ]}
              itemWidth={120}
              className="absolute top-0 left-1/2 -translate-x-1/2 h-full w-[444px] md:w-[480px] overflow-visible"
            >
              {(tab === "join" ? JOIN_SERVICES : OFFER_SERVICES).map(
                ({ price, imageMobile: image }, idx) => (
                  <MobileServiceCard
                    key={idx}
                    icon={image}
                    className={
                      tab === "join" ? "text-blue-dodger" : "text-white"
                    }
                    text={
                      <T
                        keyName={`components.landingPage.subscriptions.${tab}Mobile.monthlyprice`}
                        params={{
                          price: () => (
                            <span className="text-2xl font-bold leading-6">
                              {price}
                            </span>
                          ),
                          month: () => (
                            <T
                              keyName={`components.landingPage.subscriptions.${tab}Mobile.month`}
                            />
                          ),
                        }}
                      />
                    }
                  />
                ),
              )}
            </Carousel>
          </div>
        </div>
        <div>
          <Button
            className={twJoin(
              "py-4 px-8  text-xl leading-5 font-bold border-none shadow-[0_6px_24px_0] shadow-black/20",
              tab === "join"
                ? "bg-blue-dodger text-white active:bg-blue-royal focus:bg-blue-royal"
                : "bg-white text-blue-dodger active:bg-gray-400 focus:bg-gray-400",
            )}
            onClick={() => navigate("/auth/signup")}
          >
            <T
              keyName={`components.landingPage.subscriptions.${tab}Mobile.btn`}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

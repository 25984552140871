import { T } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { PopupSkeleton } from "@/components/Popup";
import { Error as ErrorImage } from "@/assets/illustrations";
import { LinkButton } from "@/components/Inputs/Button";

export const RemoveServiceError: React.FC = () => {
  const { id: serviceId } = useParams<{ id: string }>();

  return (
    <PopupSkeleton>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <img src={ErrorImage} className="max-w-[150px]" />
        </div>

        <h1 className="text-2xl mb-4">
          <T keyName="components.offerSubscription.offerDetails.details.removeServiceError.title" />
        </h1>

        <h1 className="text-gray-shuttle-soft text-sm mb-6">
          <T keyName="components.offerSubscription.offerDetails.details.removeServiceError.subTitle" />
        </h1>

        <div className="flex justify-center gap-6 mt-8">
          <LinkButton to={`/overview/subscriptions/offer/${serviceId}/details`}>
            <T keyName="components.offerSubscription.offerDetails.details.removeServiceError.btn" />
          </LinkButton>
        </div>
      </div>
    </PopupSkeleton>
  );
};

import { T } from "@tolgee/react";
import { Link } from "react-router-dom";
import { PopupSkeleton } from "@/components/Popup";
import { ArrowFront } from "@/assets/icons";
import { IllustrationId1 } from "@/assets/illustrations";
import { KycDocumentTypes } from "@/consts.ts";
import { KycType } from "@/gql/graphql.ts";

export const VerifyID: React.FC = () => {
  return (
    <PopupSkeleton
      showClose
      showGoBack
      onCloseLink="/overview/payments/bank-details"
    >
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <img src={IllustrationId1} alt="" />
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T keyName="components.payments.bankDetails.documentsVerification.verifyYourId.title" />
        </h3>

        <p className="text-gray-shuttle-soft mb-8">
          <T keyName="components.payments.bankDetails.documentsVerification.verifyYourId.subTitle" />
        </p>

        <div className="flex flex-col gap-4">
          {KycDocumentTypes.filter(({ id }) => id !== KycType.IbanProof).map(
            ({ id, text }) => (
              <Link
                to={`../upload?type=${id}`}
                className="bg-iron/50 w-full flex items-center justify-between gap-2 p-4
            hover:bg-blue-dodger/20 [&_path]:hover:stroke-white
            [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger"
              >
                <h5 className="text-sm">
                  <T
                    keyName={`components.payments.bankDetails.documentsVerification.verifyYourId.${text}`}
                  />
                </h5>
                <ArrowFront />
              </Link>
            ),
          )}
        </div>
      </div>
    </PopupSkeleton>
  );
};

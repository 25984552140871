import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslate } from "@tolgee/react";

export const PasswordInput: React.FC<
  {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any;
    intlKey?: string;
  } & TextFieldProps
> = ({ name, formik, intlKey, label, ...extraProps }) => {
  const { t } = useTranslate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      name={name}
      label={label ? label : t(`form.inputs.labels.${intlKey ?? name}`)}
      placeholder={t(`form.inputs.placeholders.${intlKey ?? name}`)}
      fullWidth
      required
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prev) => !prev)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...extraProps}
    />
  );
};

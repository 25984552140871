import { useNavigate } from "react-router-dom";
import { PartiziLogo } from "@/assets/icons/brands";
import { EuropeanFlag, GermanFlag } from "@/assets/landing_page/navbar/flags";
import { T } from "@tolgee/react";
import { useContext } from "react";
import { TranslationContext } from "@/providers/Translation/context";
import { useScreenDetector } from "@/hooks/ScreenDetector";
import { LinkButton } from "@/components/Inputs/Button";
import { twJoin } from "tailwind-merge";
import { AuthContext } from "@/providers/Auth/context.tsx";

export const PublicNavbar: React.FC<{ lightBg?: boolean }> = ({ lightBg }) => {
  const navigate = useNavigate();
  const { isDesktop, isTabletLandscape } = useScreenDetector();
  const translationContext = useContext(TranslationContext);
  const auth = useContext(AuthContext);
  const user = auth?.currentUser;

  return (
    <div className="bg-transparent w-full flex justify-between max-md:justify-center absolute top-0 left-0 z-[999]">
      <div
        onClick={() => navigate("/")}
        className="cursor-pointer p-[22px_0_22px_44px] max-md:p-[24px_0_0]"
      >
        <PartiziLogo className={lightBg ? "[&_path]:fill-pink-navbar" : ""} />
      </div>
      <div className="p-[14px_48px_14px_0] max-md:p-0 flex">
        {(isDesktop || isTabletLandscape) && (
          <LinkButton
            to={user ? "/overview/subscriptions" : "/auth/signup"}
            className={twJoin(
              "py-3 px-7 order-2 border-white text-white hover:bg-white hover:text-blue-dodger",
              lightBg &&
                "text-white bg-pink-navbar hover:text-white hover:bg-pink-navbar",
            )}
          >
            <T
              keyName={
                user
                  ? "components.landingPage.navbar.startAgain"
                  : "components.landingPage.navbar.startNow"
              }
            />
          </LinkButton>
        )}
        <div
          className="order-1 flex justify-center m-auto mr-8
        max-md:absolute max-md:mr-0 max-md:top-6 max-md:right-4"
        >
          <div
            onClick={() => translationContext?.updateLanguage("de")}
            className={twJoin(
              "mr-2 cursor-pointer",
              translationContext?.currentLanguage === "en" ? "opacity-40" : "",
            )}
          >
            <GermanFlag />
          </div>
          <div
            onClick={() => translationContext?.updateLanguage("en")}
            className={twJoin(
              "mr-2 cursor-pointer",
              translationContext?.currentLanguage === "de" ? "opacity-40" : "",
            )}
          >
            <EuropeanFlag />
          </div>
        </div>
      </div>
    </div>
  );
};

import { T } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SubscriptionPopupContainer } from "./PopupContainer";
import { Spinner } from "@/components/Spinner";
import { LinkButton } from "@/components/Inputs/Button";
import { DocumentsError } from "@/assets/illustrations";
import { gql } from "@/gql";

const SERVICE_DETAILS = gql(`
  query getServiceDetailsForJoinError($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      owner {
        fullName
        avatar
      }
    }
  }
`);

export const JoinSubscriptionErrorPopup: React.FC = () => {
  const { id, serviceId } = useParams<{ id: string; serviceId: string }>();

  const { data, loading } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId: serviceId! },
  });

  let content;
  if (loading) content = <Spinner />;
  else {
    content = (
      <div className="h-full flex flex-col items-center justify-center">
        <div className="flex justify-center mb-6">
          <img src={DocumentsError} alt="" />
        </div>

        <h1 className="text-2xl font-medium mb-2 text-center">
          <T keyName="components.joinSubscription.steps.error.title" />
        </h1>

        <p className="text-sm text-gray-shuttle-soft mb-4 text-center">
          <T keyName="components.joinSubscription.steps.error.info" />
        </p>

        <div className="mt-16">
          <LinkButton to="/overview/subscriptions/new-join">
            <T keyName="components.joinSubscription.steps.error.btn" />
          </LinkButton>
        </div>
      </div>
    );
  }

  return (
    <SubscriptionPopupContainer
      type="join"
      providerId={id}
      owner={data?.service.owner}
    >
      {content}
    </SubscriptionPopupContainer>
  );
};

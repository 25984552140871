import { T } from "@tolgee/react";
import { Button } from "@/components/Inputs/Button";
import { Medal, MoneyStack, Profiles } from "@/assets/landing_page/safe_sound";
import { twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";

const Perk: React.FC<{
  img: string;
  text: React.ReactNode;
  className?: string;
}> = ({ text, img, className }) => (
  <div
    className={twMerge(
      "flex max-w-[338px] w-full [&:not(:last-of-type)]:mb-9 max-md:mx-auto",
      className,
    )}
  >
    <div className="flex flex-col flex-shrink-0 justify-center mr-10">
      <img src={img} alt="" />
    </div>
    <div className="text-left text-zircon leading-[26px]">{text}</div>
  </div>
);

export const SafeAndSound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      className="relative flex max-w-[964px] w-full mx-auto mb-[26px]
    max-md:overflow-hidden max-md:mb-[100px] max-md:flex-col"
    >
      <div className="max-w-[352px] w-full text-left mt-[88px] ml-8 max-md:my-0 max-md:mx-auto max-md:text-center">
        <h2 className="text-5xl text-tuna mb-2 max-md:text-4xl max-md:mb-1">
          <T keyName="components.landingPage.saveAndSound.title" />
        </h2>
        <h4 className="text-xl text-emperor mb-8 max-md:text-lg max-md:text-tuna">
          <T keyName="components.landingPage.saveAndSound.subtitle" />
        </h4>
        <h6 className="text-gray  mb-9 max-md:mb-12">
          <T keyName="components.landingPage.saveAndSound.info" />
        </h6>

        <Button
          onClick={() => navigate("/auth/signup")}
          className="text-white bg-blue-dodger font-medium px-8 py-4 w-max hover:bg-blue-royal max-md:hidden"
        >
          <T keyName="components.landingPage.saveAndSound.btn" />
        </Button>
      </div>
      <div
        className="flex flex-col flex-grow h-[570px] relative md:p-[113px_112px_120px_122px]
        box-border bg-no-repeat bg-top bg-blue-ball max-md:justify-center"
      >
        <Perk
          img={Medal}
          text={
            <T
              keyName="components.landingPage.saveAndSound.perks.first.text"
              params={{
                approved: () => (
                  <span className="font-medium">
                    <T keyName="components.landingPage.saveAndSound.perks.first.approved" />
                  </span>
                ),
              }}
            />
          }
          className="max-md:[&_img]:w-[72px] max-md:[&_img]:h-[78px]"
        />
        <Perk
          img={MoneyStack}
          text={
            <T
              keyName="components.landingPage.saveAndSound.perks.second.text"
              params={{
                money: () => (
                  <span className="font-medium">
                    <T keyName="components.landingPage.saveAndSound.perks.second.money" />
                  </span>
                ),
              }}
            />
          }
          className="max-md:[&_img]:w-[72px] max-md:[&_img]:h-[60px]"
        />
        <Perk
          img={Profiles}
          text={
            <T
              keyName="components.landingPage.saveAndSound.perks.third.text"
              params={{
                verified: () => (
                  <span className="font-medium">
                    <T keyName="components.landingPage.saveAndSound.perks.third.verified" />
                  </span>
                ),
              }}
            />
          }
          className="max-md:[&_img]:w-[72px] max-md:[&_img]:h-[78px]"
        />
      </div>
      <div className="flex justify-center md:hidden">
        <Button onClick={() => navigate("/auth/signup")}>
          <T keyName="components.landingPage.saveAndSound.btn" />
        </Button>
      </div>
    </div>
  );
};

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { onError } from "@apollo/client/link/error";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { toastError } from "@/utils/toast";
import { KEYS } from "@/consts.ts";

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL,
  headers: {},
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_API_WS,
    connectionParams: () => ({
      [KEYS.ACCESS_TOKEN]: localStorage.getItem(KEYS.ACCESS_TOKEN),
    }),
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    auth: {
      username: import.meta.env.VITE_API_USER,
      password: import.meta.env.VITE_API_KEY,
    },
    [KEYS.ACCESS_TOKEN]: localStorage.getItem(KEYS.ACCESS_TOKEN),
  },
}));

let hasAuthError = false;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (hasAuthError && localStorage.getItem(KEYS.ACCESS_TOKEN)) {
    hasAuthError = false;
  }

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Not Authorised!" && !hasAuthError) {
        hasAuthError = true;
        toastError("Please login to continue");
        localStorage.removeItem(KEYS.ACCESS_TOKEN);
        // window.location.replace("/");
        // return;
      }

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, splitLink]),
  connectToDevTools: import.meta.env.NODE_ENV === "development",
});

import { T } from "@tolgee/react";
import { SubscriptionPopupContainer } from "./PopupContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { CreditCardProvider } from "@/assets/illustrations";
import { Button } from "@/components/Inputs/Button";
import { ImgProfile } from "@/components/ImgProfile";
import { CheckBall } from "@/assets/icons";
import { toastError } from "@/utils/toast";
import { gql } from "@/gql";
import invariant from "tiny-invariant";

const SERVICE_DETAILS = gql(`
  query getServiceDetailsForJoinPayment($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      owner {
        fullName
        firstName
        avatar
      }
      provider {
        name
        brand
      }
    }
  }
`);

const PRE_AUTH = gql(`
  mutation preAuth($serviceId: String!) {
    token: preAuth(service: { id: $serviceId }) {
      webInitUrl
    }
  }
`);

export const JoinSubscriptionPaymentPopup: React.FC = () => {
  const navigate = useNavigate();
  const { id, serviceId } = useParams<{ id: string; serviceId: string }>();
  if (!id || !serviceId) navigate("/overview/subscriptions");

  const { data, loading } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId: serviceId! },
  });

  const [preAuth, preAuthResult] = useMutation(PRE_AUTH, {
    variables: { serviceId: serviceId! },
  });

  let content;
  if (loading) content = <Spinner />;
  else {
    invariant(data, "Data should be resolved");

    const steps = [
      {
        key: "first",
        img: <img src={CreditCardProvider} alt="" />,
      },
      {
        key: "second",
        img: (
          <div className="w-[75px] h-[75px] relative">
            <ImgProfile img={data.service.owner.avatar} />
            <div className="absolute bottom-[-5px] right-0">
              <CheckBall />
            </div>
          </div>
        ),
      },
      {
        key: "third",
        img: (
          <div className="border border-gray p-2 rounded-lg">
            <div
              className="w-12 h-12 bg-white bg-cover bg-no-repeat bg-center rounded"
              style={{
                backgroundImage: `url("${data.service.provider.brand}")`,
              }}
            />
          </div>
        ),
      },
    ];

    if ((preAuthResult.loading || preAuthResult.data) && !preAuthResult.error) {
      content = (
        <div className="flex flex-col h-full justify-center items-center">
          <div className="mb-8">
            <Spinner />
          </div>
          <img src={CreditCardProvider} alt="" />
          <h6 className="text-xs text-bombay">
            <T keyName="components.joinSubscription.steps.paymentMethod.bnpInfo" />
          </h6>
        </div>
      );
    } else {
      invariant(data, "Data should be resolved");

      content = (
        <>
          <h1 className="text-2xl font-medium mb-2">
            <T keyName="components.joinSubscription.steps.paymentMethod.title" />
          </h1>
          <h6 className="text-gray-shuttle-soft mb-8 text-sm max-md:text-base">
            <T
              keyName="components.joinSubscription.steps.paymentMethod.subtitle"
              params={{
                owner: () => data.service.owner.firstName,
              }}
            />
          </h6>

          <div>
            {steps.map((item) => (
              <div
                className="flex py-3 px-2 first:bg-blue-dodger/10 first:rounded-2xl first:mb-1"
                key={item.key}
              >
                <div className="w-[120px] flex-shrink-0 flex justify-center max-md:h-[75px]">
                  {item.img}
                </div>
                <div className="ml-3">
                  <div className="text-lg font-medium text-gray-100 mb-1 max-md:mb-2">
                    <T
                      keyName={`components.joinSteps.${item.key}.title`}
                      params={{
                        owner: () => data.service.owner.firstName,
                        service: () => data.service.provider.name,
                      }}
                    />
                  </div>
                  <div className="text-sm text-gray-shuttle-soft">
                    <T
                      keyName={`components.joinSteps.${item.key}.subtitle`}
                      params={{
                        owner: () => data.service.owner.firstName,
                        service: () => data.service.provider.name,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="md:hidden h-28" />
          <div className="flex justify-end items-end max-md:items-center gap-4 max-md:pt-4 flex-col max-md:justify-center max-md:fixed max-md:bg-white max-md:bottom-0 max-md:w-full max-md:h-28 md:mt-auto max-md:inset-x-0">
            <Button
              className="w-[200px]"
              onClick={() =>
                preAuth()
                  .then((res) => {
                    if (res?.data?.token?.webInitUrl)
                      window.location.href = res.data.token.webInitUrl;
                    else toastError("An error occurred");
                  })
                  .catch((err) =>
                    toastError(err?.message ?? (err || "An error occurred")),
                  )
              }
            >
              <T keyName="components.joinSubscription.steps.paymentMethod.btn" />
            </Button>
            <h6 className="text-xs text-gray-shuttle-soft max-md:text-base -mt-2">
              <T
                keyName="components.joinSubscription.steps.paymentMethod.btnDesc"
                params={{
                  owner: () => data.service.owner.firstName,
                }}
              />
            </h6>
          </div>
        </>
      );
    }
  }

  return (
    <SubscriptionPopupContainer
      type="join"
      providerId={id}
      owner={data?.service.owner}
    >
      {content}
    </SubscriptionPopupContainer>
  );
};

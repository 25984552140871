import { T } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import { HourGlass } from "@/assets/illustrations";
import { PopupSkeleton } from "@/components/Popup";
import { Button } from "@/components/Inputs";

export const WarningRequest: React.FC = () => {
  const { id: serviceId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const goToServiceRequests = () =>
    navigate(`/overview/subscriptions/offer/${serviceId}/requests`, {
      state: "refetch",
    });

  return (
    <PopupSkeleton showClose onClose={goToServiceRequests}>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={HourGlass} alt="" />
        </div>
        <h1 className="text-2xl mb-4">
          <T keyName="components.offerSubscription.offerDetails.requests.warning.title" />
        </h1>
        <h6 className="text-sm text-gray-shuttle mb-4">
          <T keyName="components.offerSubscription.offerDetails.requests.warning.subtitle" />
        </h6>

        <div className="flex justify-center mt-8">
          <Button
            onClick={goToServiceRequests}
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T keyName="components.offerSubscription.offerDetails.requests.warning.btn" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  );
};

import { T } from "@tolgee/react";
import { useQuery } from "@apollo/client";
import { Link, useLocation, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { Spinner } from "@/components/Spinner";
import { PopupOutlet } from "@/components/Popup";
import { twJoin } from "tailwind-merge";
import { EmptyRequestsList, HourGlass } from "@/assets/illustrations";
import moment from "moment";
import { ScrollRefContext } from "@/contexts";
import { useContext, useEffect, useState } from "react";
import { ImgProfile } from "@/components/ImgProfile";
import { Check, Close } from "@/assets/icons";
import _ from "lodash";
import { gql } from "@/gql";
import invariant from "tiny-invariant";

interface Request {
  id: string;
  requestBy: { avatar?: string | null; fullName: string };
  createdAt: Date;
  isLastRequest: boolean;
}

const PENDING_REQUESTS = gql(`
  query allPendingRequestsFromService(
    $serviceId: String!
    $skip: Float
    $after: RequestWhereUniqueInput
    $first: Float!
  ) {
    requests: requests(
      where: { serviceId: $serviceId, status: PENDING }
      skip: $skip
      after: $after
      first: $first
      orderBy: { createdAt: desc }
    ) {
      id
      requestBy {
        avatar
        fullName
      }
      createdAt
      isLastRequest
    }
    service: service(where: { id: $serviceId }) {
      numFreeSlots
    }
  }
`);

export const Requests: React.FC = () => {
  const scrollRef = useContext(ScrollRefContext)!;
  const { id: serviceId } = useParams<{ id: string }>();
  invariant(serviceId, "Service ID should be provided by route");
  const { state } = useLocation();

  const { data, loading, fetchMore, refetch } = useQuery(PENDING_REQUESTS, {
    variables: { serviceId, first: 20 },
  });

  useEffect(() => {
    if (state === "refetch") refetch();
  }, [refetch, state]);

  const [requests, setRequests] = useState<Request[]>([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (data?.requests) {
      setRequests(data.requests);
      setHasMore(data.requests.length === 20);
    }
  }, [data]);

  const loadMore = async () => {
    const { data } = await fetchMore({
      variables: {
        after: {
          id: requests[requests.length - 1].id,
        },
        skip: 1,
      },
    });

    if (!data || _.isEmpty(data.requests)) {
      setHasMore(false);
      return;
    }

    const newRequests = _.chain(requests)
      .concat(data.requests)
      .sortedUniqBy("createdAt")
      .value();
    setRequests(newRequests);
  };

  if (loading || !data?.service || !data?.requests) return <Spinner />;

  const numRequests = data.requests.length;
  const availableSpots = data.service.numFreeSlots;
  const lastRequestTime = data.requests.find((r) => r.isLastRequest)?.createdAt;

  return (
    <>
      <div className="flex justify-between items-start mb-8">
        <div>
          <h3 className="text-lg font-medium">
            <T
              keyName="components.offerSubscription.offerDetails.requests.pendingRequests"
              params={{
                requests: () => (
                  <span
                    className={twJoin(
                      "p-1 ml-1 rounded-full text-white text-sm",
                      numRequests === 0 ? "bg-pink-brink" : "bg-blue-dodger",
                    )}
                  >
                    <span className={twJoin(numRequests < 10 && "p-1")}>
                      {numRequests}
                    </span>
                  </span>
                ),
              }}
            />
          </h3>
          {lastRequestTime && (
            <h5 className="mt-2 text-gray-shuttle-soft">
              <T
                keyName="components.offerSubscription.offerDetails.requests.lastRequest"
                params={{ time: () => moment(lastRequestTime).fromNow(true) }}
              />
            </h5>
          )}
        </div>

        <h5 className="text-sm text-blue-dodger mt-1">
          <T
            keyName="components.offerSubscription.offerDetails.requests.spostsLeft"
            params={{
              spots: () => availableSpots,
            }}
          />
        </h5>
      </div>

      <div className="flex">
        <div className="flex-grow">
          {numRequests === 0 ? (
            <div className="flex flex-col items-center text-center mt-8">
              <img
                src={EmptyRequestsList}
                alt=""
                className="max-w-[112px] w-full mb-8"
              />
              <h2 className="text-gray-shuttle-soft font-bold text-2xl mb-1">
                <T keyName="emptyStates.offerRequests.oops" />
              </h2>
              <h3 className="text-gray-shuttle-soft text-lg mb-4">
                <T keyName="emptyStates.offerRequests.noRequests" />
              </h3>
              <Link className="text-blue-dodger" to="popup/invite-friends">
                <T keyName="emptyStates.offerRequests.invite" />
              </Link>
            </div>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              useWindow={false}
              getScrollParent={() => scrollRef.current}
              initialLoad={false}
              hasMore={hasMore}
              loader={<Spinner small key={0} />}
              className="md:pr-8"
            >
              {requests.map((request) => (
                <div
                  key={request.id}
                  className="flex items-center mb-4 gap-4 pb-2 border-b border-b-gray-400"
                >
                  <ImgProfile
                    img={request.requestBy.avatar}
                    className="w-8 h-8 max-md:w-12 max-md:h-12"
                  />
                  <div className="flex-grow flex md:items-center md:gap-4 max-md:flex-col">
                    <p className="text-sm md:basis-full max-md:text-base">
                      {request.requestBy.fullName}
                    </p>
                    <p className="text-xs text-gray-shuttle-soft md:basis-full max-md:text-sm">
                      {moment(request.createdAt).fromNow()}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 max-md:gap-6">
                    <Link to={`popup/decline-request/${request.id}`}>
                      <Close
                        className="[&_circle]:hover:fill-pink-brink
                      [&_circle]:hover:stroke-pink-brink [&_path]:hover:stroke-white"
                      />
                    </Link>
                    <Link to={`popup/accept-request/${request.id}`}>
                      <Check
                        className="[&_circle]:hover:fill-blue-dodger
                        [&_circle]:hover:stroke-blue-dodger [&_path]:hover:stroke-white"
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>

        <div
          className="flex-shrink-0 flex flex-col items-center max-w-[328px] max-h-[300px] w-full h-full
            border border-dashed border-gray-shuttle-soft box-border text-center py-10 px-6 max-md:hidden"
        >
          <img src={HourGlass} alt="" className="max-w-[105px] mb-6" />
          <h4 className="text-lg text-gray-100 font-medium mb-4">
            <T
              keyName={
                numRequests === 0
                  ? "components.offerSubscription.offerDetails.requests.saveTime"
                  : "components.offerSubscription.offerDetails.requests.beforeContinue"
              }
            />
          </h4>
          <p className="text-xs text-gray-shuttle-soft">
            <T
              keyName={
                numRequests === 0
                  ? "components.offerSubscription.offerDetails.requests.speedUpTime"
                  : "components.offerSubscription.offerDetails.requests.beAware"
              }
            />
          </p>
        </div>
      </div>

      <PopupOutlet />
    </>
  );
};

import { T, useTranslate } from "@tolgee/react";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { useNavigate } from "react-router-dom";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Spinner } from "@/components/Spinner";
import { SocialAuthProviders } from "@/gql/graphql.ts";

export const SocialLogIn: React.FC<{ type: string }> = ({ type }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<number | null>(null);

  const authContext = useContext(AuthContext);

  const handleSocialSignIn = useCallback(
    (provider: SocialAuthProviders, res: CredentialResponse) => {
      let token: string;
      if (provider === SocialAuthProviders.Google) {
        if (!res.credential) {
          console.log("No credential found");
          setError(401);
          return;
        }
        token = res.credential;
      } else {
        setError(501);
        return;
      }

      setLoading(true);
      setError(null);

      authContext
        ?.socialLogIn({ provider, token })
        .then(() => navigate("/overview/subscriptions"))
        .catch((err: { [x: string]: { statusCode: number }[] }) => {
          setError(err?.["graphQLErrors"]?.[0]?.statusCode ?? 500);
          setLoading(false);
        });
    },
    [authContext, navigate],
  );

  if (loading) return <Spinner />;

  return (
    <div className="w-full flex text-center justify-center bg-[#c4cfff]">
      <div className="w-full flex flex-col bg-blue-dodger/20">
        <p className="text-xs text-gray-shuttle-soft mx-auto my-4">
          <T keyName={`components.${type}.media`} />
        </p>
        <div className="text-xs text-pink-brink">
          {error === 401
            ? t("form.inputs.errors.invalidCredentials")
            : error === 403
              ? t("form.inputs.errors.needValidation")
              : error === 501
                ? t("form.inputs.errors.notImplemented")
                : null}
        </div>
        <div className="flex flex-col items-center gap-2 m-auto mb-4">
          <GoogleLogin
            onSuccess={(res) =>
              handleSocialSignIn(SocialAuthProviders.Google, res)
            }
            useOneTap
          />
        </div>
      </div>
    </div>
  );
};

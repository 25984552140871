import { T, useTranslate } from "@tolgee/react";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { Spinner } from "@/components/Spinner";
import { useFormik } from "formik";
import { toastError, toastSuccess } from "@/utils/toast";
import { Button, PasswordInput, TextInput } from "@/components/Inputs";
import { LinkButton } from "@/components/Inputs/Button";
import { PopupOutlet } from "@/components/Popup";
import { useEffect, useState } from "react";
import { gql } from "@/gql";
import invariant from "tiny-invariant";
import { ImgProfile } from "@/components/ImgProfile";
import { twJoin } from "tailwind-merge";
import { IconButton } from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";

const SERVICE_DETAILS = gql(`
  query serviceCredentials($serviceId: String!) {
    service(where: { id: $serviceId }) {
      provider {
        name
        website
        joinerEmailRequired
      }
      credentials {
        email
        password
      }
    }
  }
`);

const REQUEST_WITH_SLOTS = gql(`
  query GetRequestsWithSlots($serviceId: String!) {
    getRequestsWithSlots(serviceId: $serviceId) {
      id
      associatedSlot {
        status,
        usedBy {
          firstName
          lastName
          avatar
        }
      }
      joinerEmail
    }
  }
`);

const UPDATE_CREDENTIALS = gql(`
  mutation updateServiceAuth(
    $serviceId: String!
    $email: String!
    $password: String!
  ) {
    credentials: updateService(
      data: { serviceEmail: $email, servicePassword: $password }
      where: { id: $serviceId }
    ) {
      email: serviceEmail
      password: servicePassword
    }
  }
`);

export const Details: React.FC = () => {
  const { t } = useTranslate();
  const { id } = useParams<{ id: string }>();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toastSuccess(t("toastMessages.copyToClipboard.success"));
      },
      () => {
        toastError(t("toastMessages.copyToClipboard.error"));
      },
    );
  };

  const { data, loading } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId: id! },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [activeUsers, setActiveUsers] = useState<any[]>([]);

  const { data: slotData } = useQuery(REQUEST_WITH_SLOTS, {
    variables: { serviceId: id! },
  });

  useEffect(() => {
    if (!slotData) return;

    const activeUsers = slotData.getRequestsWithSlots.map((item) => {
      return {
        usedBy: item.associatedSlot?.usedBy,
        joinerEmail: item.joinerEmail,
      };
    });

    setActiveUsers(activeUsers);
  }, [slotData]);

  const [updateCredentials] = useMutation(UPDATE_CREDENTIALS);

  const validationSchema = yup.object().shape({
    email: yup.string().required(t("form.inputs.errors.email")),
    password: yup.string().required(t("form.inputs.errors.password")),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      try {
        await updateCredentials({
          variables: {
            serviceId: id!,
            email: values.email,
            password: values.password,
          },
        });
        formikHelpers.setSubmitting(false);
        toastSuccess(t("toastMessages.personalSettings.success"));
      } catch (e) {
        formikHelpers.setSubmitting(false);
        console.log(e);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toastError((e as any)?.message ?? e ?? "An error occurred");
      }
    },
  });

  useEffect(() => {
    if (data && formik) {
      formik.setValues(data.service.credentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Spinner />;

  invariant(data, "Data should be resolved");
  const { service } = data;

  return (
    <>
      {!service.provider.joinerEmailRequired && (
        <>
          <h3 className="text-lg font-medium mb-8">
            <T keyName="components.offerSubscription.offerDetails.details.title" />
          </h3>

          <form onSubmit={formik.handleSubmit} className="mb-10">
            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6">
              <TextInput
                name="email"
                formik={formik}
                label={t("form.inputs.labels.serviceAccountIdentifier", {
                  provider: () => service.provider.name,
                })}
              />
              <PasswordInput
                name="password"
                formik={formik}
                label={t("form.inputs.labels.serviceAccountPassword", {
                  provider: () => service.provider.name,
                })}
              />
            </div>

            <div className="flex gap-4 mt-8">
              <Button
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  (formik.values.email === data.service.credentials.email &&
                    formik.values.password ===
                      data.service.credentials.password)
                }
              >
                <T keyName="components.offerSubscription.offerDetails.details.saveAndShare" />
              </Button>

              <LinkButton to={service.provider.website ?? "#"} target="_blank">
                <T
                  keyName="components.offerSubscription.offerDetails.details.goToService"
                  params={{
                    service: () => service.provider.name,
                  }}
                />
              </LinkButton>
            </div>
          </form>
        </>
      )}

      {service.provider.joinerEmailRequired && (
        <>
          <h3 className="text-lg font-medium mb-4">
            <T keyName="components.offerSubscription.offerDetails.details.additionalProfilesInvitations.title" />
          </h3>

          <p className="text-gray-shuttle-soft text-sm mb-4">
            <T keyName="components.offerSubscription.offerDetails.details.additionalProfilesInvitations.details" />
          </p>
          {activeUsers.map((user) => (
            <div className="flex items-center gap-6 pb-2">
              <ImgProfile
                img={user?.avatar}
                className={twJoin(
                  "w-8 h-8 border border-transparent shadow-[0_0_0_1px]",
                )}
              />
              <p>
                {user?.usedBy.firstName}: {user?.joinerEmail}
                <IconButton
                  aria-label="copy to clipboard"
                  onClick={() => copyToClipboard(user.joinerEmail ?? "")}
                  edge="end"
                >
                  <ContentCopy />
                </IconButton>
              </p>
            </div>
          ))}

          <div className="flex gap-4 mt-4 mb-4">
            <LinkButton to={service.provider.website ?? "#"} target="_blank">
              <T
                keyName="components.offerSubscription.offerDetails.details.goToService"
                params={{
                  service: () => service.provider.name,
                }}
              />
            </LinkButton>
          </div>
        </>
      )}
      <h3 className="text-lg font-medium mb-4">
        <T keyName="components.offerSubscription.offerDetails.details.removeOffer" />
      </h3>

      <p className="text-gray-shuttle-soft text-sm">
        <T
          keyName="components.offerSubscription.offerDetails.details.removeInfo"
          params={{
            service: () => service.provider.name,
            remove: () => (
              <Link to="popup/remove-service" className="text-blue-dodger">
                <T
                  keyName="components.offerSubscription.offerDetails.details.removeServiceLink"
                  params={{
                    service: () => service.provider.name,
                  }}
                />
              </Link>
            ),
          }}
        />
      </p>
      <PopupOutlet />
    </>
  );
};

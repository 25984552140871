import { T, useTranslate } from "@tolgee/react";
import { useState } from "react";
import * as yup from "yup";
import { Avatar1 } from "@/assets/images";
import { useFormik } from "formik";
import { Button } from "@/components/Inputs/Button";
import { AvatarPicker, TextInput } from "@/components/Inputs";
import { useNavigate } from "react-router-dom";

export const FirstScreen: React.FC<{
  handleSubmit: (values: {
    firstName: string;
    lastName: string;
    avatar: string;
  }) => void;
}> = ({ handleSubmit }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z]+$/, t("form.inputs.errors.firstname"))
      .required(t("form.inputs.errors.firstname")),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z]+$/, t("form.inputs.errors.lastname"))
      .required(t("form.inputs.errors.lastname")),
  });

  const [avatar, setAvatar] = useState<string>(Avatar1);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const firstName = `${values.firstName.charAt(0).toUpperCase()}${values.firstName.slice(1)}`;
      const lastName = `${values.lastName.charAt(0).toUpperCase()}${values.lastName.slice(1)}`;
      handleSubmit({ firstName, lastName, avatar });
    },
  });

  const canSubmit = !(
    formik.isSubmitting ||
    formik.values.firstName.length < 2 ||
    formik.values.lastName.length < 2 ||
    !formik.isValid ||
    formik.values.firstName.toUpperCase() ===
      formik.values.lastName.toUpperCase()
  );

  return (
    <div className="p-10 max-md:p-4">
      <div className="flex items-baseline mb-6 max-md:flex-col">
        <div className="text-gray-100 text-2xl mr-2">
          <T keyName="components.signup.steps.first.title" />
        </div>
        <div
          onClick={() => navigate("/auth/login")}
          className="text-blue-dodger cursor-pointer text-sm"
        >
          <T keyName="components.signup.steps.first.signin" />
        </div>
      </div>
      <div className="flex flex-col mb-7">
        <div className="text-xs text-gray-shuttle-soft mb-2">
          <T keyName="components.signup.steps.first.pickAvatar" />
        </div>
        <div className="m-auto">
          <AvatarPicker
            setAvatar={setAvatar}
            avatar={avatar}
            className="large"
          />
        </div>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-4 items-center mt-8">
            <TextInput name="firstName" formik={formik} intlKey="firstname" />
            <TextInput name="lastName" formik={formik} intlKey="lastname" />
            <Button type="submit" disabled={!canSubmit} className="mt-4 w-fit">
              <T keyName="components.signup.steps.first.button" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

import { useQuery } from "@apollo/client";
import { T } from "@tolgee/react";
import _ from "lodash";
import { Spinner } from "@/components/Spinner";
import { Link, useMatches, useNavigate, useParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { ArrowBack, ArrowSecondary, Close } from "@/assets/icons";
import { useContext } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { ImgProfile } from "@/components/ImgProfile";
import { HeartSolid, Savings } from "@/assets/illustrations";
import { gql } from "@/gql";
import { ProviderStatus } from "@/gql/graphql.ts";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { match, P } from "ts-pattern";

const PROVIDERS = gql(`
  query getAllProviders {
    providers {
      id
      name
      type
      status
      price {
        id
        fullPrice
        commission
        offerReturn
      }
      brand
      numberOfSlots
    }
  }
`);

const PROVIDER_DETAILS = gql(`
  query getProviderDetailsForSidebar($providerId: String!) {
    provider(where: { id: $providerId }) {
      id
      name
      price {
        id
        offerReturn
        fullPrice
      }
      brand
      numberOfSlots
    }
  }
`);

export const SubscriptionPopupSidebar: React.FC<{
  type: "join" | "offer";
  providerId?: string;
  owner?: {
    fullName: string;
    avatar?: string | null;
  };
  showCloseButton?: boolean;
}> = ({ type, providerId, owner, showCloseButton = true }) => {
  const { id } = useParams<{ id?: string }>();

  const navigate = useNavigate();

  return (
    <div
      className={twJoin(
        "bg-gray-100 text-white h-auto py-16 rounded-l-xl whitespace-nowrap",
        "relative max-md:w-full max-md:z-10 max-md:rounded-l-none max-md:py-4",
        !id && "max-md:absolute max-md:h-full",
      )}
    >
      <div
        className="absolute cursor-pointer top-6 left-6 z-10 max-md:top-4
        [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger"
      >
        <ArrowBack
          onClick={() => navigate(-1)}
          className="max-md:w-8 max-md:h-8"
        />
      </div>

      {showCloseButton && (
        <div
          className="absolute cursor-pointer top-6 right-6 z-10
          [&_circle]:hover:fill-pink-brink [&_circle]:hover:stroke-pink-brink
          [&_path]:hover:stroke-white md:hidden max-md:top-4 max-md:right-4"
        >
          <Close
            onClick={() => navigate("/overview/subscriptions")}
            className="max-md:w-8 max-md:h-8"
          />
        </div>
      )}

      <h1 className="text-lg font-medium mb-6 px-10 max-md:px-4 max-md:text-2xl max-md:ml-12">
        <T keyName={`components.${type}Subscription.title`} />
      </h1>

      {(type === "offer" && providerId) ||
      (type === "join" && providerId && owner) ? (
        <SubscriptionPopupSidebarProviderDetails
          type={type}
          providerId={providerId}
          owner={owner}
        />
      ) : (
        <SubscriptionPopupSidebarProviders type={type} />
      )}
    </div>
  );
};

export const SubscriptionPopupSidebarProviderDetails: React.FC<{
  type: "join" | "offer";
  providerId: string;
  owner?: {
    fullName: string;
    avatar?: string | null;
  };
}> = ({ type, providerId, owner }) => {
  const { currentUser } = useContext(AuthContext)!;

  const { data } = useQuery(PROVIDER_DETAILS, {
    variables: { providerId },
  });

  const pricePerSlot = data
    ? type === "offer"
      ? data.provider.price.offerReturn * data.provider.numberOfSlots
      : data.provider.price.fullPrice
    : 0;

  return (
    <div className="flex flex-col h-full mt-8 max-md:h-fit max-md:mt-0 md:opacity-50 md:hover:opacity-100 md:transition-opacity md:duration-100">
      {/* For desktop */}
      {owner && (
        <div className="flex items-center gap-6 justify-center max-md:hidden mx-4">
          <ImgProfile img={owner ? owner?.avatar : currentUser?.avatar} />
          <h1 className="text-lg font-bold max-w-full text-wrap whitespace-break-spaces break-all">
            {owner ? owner.fullName : currentUser?.fullName}
          </h1>
        </div>
      )}
      <div className="flex-grow max-md:hidden" />
      {type === "join" && (
        <div className="mb-10 px-10 max-md:hidden">
          <h2 className="text-sm">
            <T keyName="components.joinSubscription.steps.howDoesThisWork.subtitle2" />
          </h2>
          <div className="flex gap-4 items-center mt-4">
            <img className="w-[30px] h-[30px]" src={HeartSolid} alt="" />
            <div className="text-sm">
              <T keyName="components.joinSubscription.steps.howDoesThisWork.risk1Title" />
            </div>
          </div>
          <div className="flex gap-4 items-center mt-4">
            <img className="w-[30px] h-[30px]" src={Savings} alt="" />
            <div className="text-sm">
              <T keyName="components.joinSubscription.steps.howDoesThisWork.risk2Title" />
            </div>
          </div>
        </div>
      )}
      <div className="flex cursor-pointer py-3 px-4 bg-darkshark rounded mx-10 max-md:hidden">
        <div
          className="w-10 h-10 flex-shrink-0 bg-white bg-cover bg-no-repeat bg-center mr-4 rounded"
          style={{
            backgroundImage: `url("${data?.provider.brand}")`,
          }}
        />
        <div className="flex flex-col whitespace-nowrap">
          <h6 className="text-sm">{data?.provider.name}</h6>
          <p className="text-xs text-bombay">
            {type === "join" && (
              <>
                <T keyName="components.serviceSelector.only" />{" "}
              </>
            )}
            {`€${pricePerSlot.toFixed(2)} / `}
            <T keyName="components.serviceSelector.month" />
          </p>
        </div>
      </div>

      {/* For mobile */}
      <div className="md:hidden grid grid-cols-2 whitespace-normal">
        <div className="flex items-center justify-center gap-4">
          <div
            className="w-12 h-12 flex-shrink-0 bg-white bg-cover bg-no-repeat bg-center rounded"
            style={{
              backgroundImage: `url("${data?.provider.brand}")`,
            }}
          />
          <ArrowSecondary />
          <ImgProfile
            img={owner ? owner?.avatar : currentUser?.avatar ?? ""}
            className="w-12 h-12 m-0"
          />
        </div>
        <div>
          <h6 className="text-lg">
            {owner
              ? owner?.fullName.split(" ")[0]
              : currentUser?.fullName.split(" ")[0]}
            's {data?.provider.name}
          </h6>
          <p className="text-sm text-bombay">
            {type === "join" && (
              <>
                <T keyName="components.serviceSelector.only" />{" "}
              </>
            )}
            {`€${pricePerSlot.toFixed(2)} / `}
            <T keyName="components.serviceSelector.month" />
          </p>
        </div>
      </div>

      <h6 className="mt-4 mb-16 text-xs text-gray-400 mx-10 whitespace-normal max-md:text-sm max-md:mx-4 max-md:mb-0">
        <T
          keyName={`components.${type}Subscription.selectServiceInfo`}
          params={{
            provider: () => data?.provider.name.split(" ")[0] ?? "",
          }}
        />
      </h6>
    </div>
  );
};

export const SubscriptionPopupSidebarProviders: React.FC<{
  type: "join" | "offer";
}> = ({ type }) => {
  const { id } = useParams<{ id?: string }>();

  const matches = useMatches();
  const isProviderRequest =
    matches.at(-1)?.pathname.endsWith("provider-request") ?? false;

  const { data, loading } = useQuery(PROVIDERS);

  let providersByTypes: {
    type: string;
    providers: {
      id: string;
      name: string;
      slots: number;
      type: string;
      price: {
        fullPrice: number;
        offerReturn: number;
        commission: number;
      };
      isAvailable: boolean;
      serviceIcon: string;
    }[];
  }[] = [];
  if (data?.providers && data.providers.length > 0) {
    providersByTypes = _.chain(
      data.providers.map(
        ({ id, name, type, status, price, numberOfSlots, brand }) => ({
          id: id as string,
          name: name as string,
          slots: numberOfSlots as number,
          type: (type.charAt(0).toUpperCase() +
            type.toLowerCase().slice(1)) as string,
          price: {
            fullPrice: price.fullPrice as number,
            offerReturn: price.offerReturn as number,
            commission: price.commission as number,
          },
          isAvailable: status === ProviderStatus.Active,
          serviceIcon: brand,
        }),
      ),
    )
      .groupBy("type")
      .map((providers, type) => ({ type, providers }))
      .value();
  }

  return (
    <div className="flex flex-col justify-between h-full">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex flex-col">
            {providersByTypes.map(({ type: providerType, providers }) => (
              <div key={providerType}>
                <h2
                  className={twJoin(
                    "my-2 px-10 max-md:px-4",
                    id && "max-md:hidden",
                  )}
                >
                  {providerType}
                </h2>
                <div>
                  {providers.map((p) => (
                    <Link
                      key={p.id}
                      className={twJoin(
                        "flex cursor-pointer py-3 px-10 z-10 hover:bg-darkshark relative max-md:px-4",
                        id &&
                          (p.id !== id
                            ? "opacity-50 max-md:hidden"
                            : "md:bg-darkshark md:before:bg-darkshark md:before:block md:before:absolute md:before:h-full " +
                              "md:before:w-full md:before:-z-10 md:before:top-0 md:before:-right-2 md:before:rounded-r"),
                      )}
                      to={`/overview/subscriptions/new-${type}/${p.id}`}
                    >
                      <img
                        className="w-[32px] h-[32px] bg-white bg-cover bg-no-repeat bg-center mr-4 rounded max-md:w-[60px] max-md:h-[60px] shrink-0"
                        style={{
                          backgroundImage: `url("${p.serviceIcon}")`,
                        }}
                      />
                      <div className="flex flex-col">
                        <h6
                          className={twJoin(
                            "text-sm",
                            !id ? "max-md:text-base" : "max-md:text-lg",
                          )}
                        >
                          {p.name}
                          <span
                            className={twJoin(
                              "ml-2 text-[10px] text-blue-dodger max-md:text-base",
                              p.isAvailable && "hidden",
                            )}
                          >
                            <T keyName="components.serviceSelector.comingSoon" />
                          </span>
                        </h6>
                        <p
                          className={twJoin(
                            "text-bombay text-xs",
                            id && "max-md:text-base max-md:mt-1",
                          )}
                        >
                          <T
                            keyName={`components.serviceSelector.${type === "join" ? "only" : "upTo"}`}
                          />
                          {` €${
                            type === "offer"
                              ? (p.price.offerReturn * p.slots).toFixed(2)
                              : p.price.fullPrice.toFixed(2)
                          } / `}
                          <T keyName="components.serviceSelector.month" />
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Link
            className={twJoin(
              "flex cursor-pointer py-3 px-10 z-10 hover:bg-darkshark relative max-md:px-4",
              match({ id, isProviderRequest })
                .with(
                  { id: P.any, isProviderRequest: true },
                  () =>
                    "md:bg-darkshark md:before:bg-darkshark md:before:block md:before:absolute md:before:h-full " +
                    "md:before:w-full md:before:-z-10 md:before:top-0 md:before:-right-2 md:before:rounded-r",
                )
                .with(
                  { id: P.string, isProviderRequest: P.boolean },
                  () => "opacity-50 max-md:hidden",
                )
                .with({ id: P.nullish, isProviderRequest: P.boolean }, () => "")
                .exhaustive(),
            )}
            to={`/overview/subscriptions/popup/provider-request`}
            state={{ isProviderRequest: true, type }}
          >
            <AddCircleOutlineIcon className="w-[32px] h-[32px] ml-2 mr-4 max-md:w-[60px] max-md:h-[60px] shrink-0" />
            <div className="flex flex-col">
              <h6
                className={twJoin(
                  "text-sm",
                  !id ? "max-md:text-base" : "max-md:text-lg",
                )}
              >
                Other subscription
              </h6>
              <p
                className={twJoin(
                  "text-bombay text-xs",
                  id && "max-md:text-base max-md:mt-1",
                )}
              >
                Request a new subscription
              </p>
            </div>
          </Link>
        </>
      )}
    </div>
  );
};

import { T } from "@tolgee/react";
import { useScreenDetector } from "@/hooks/ScreenDetector";
import { JoinUsers } from "@/assets/landing_page/save_your_spot";
import { JoinUsersMobile } from "@/assets/illustrations/mobile";

export const SaveYourSpot: React.FC = () => {
  const { isBig } = useScreenDetector();
  return (
    <div
      className="max-w-[853px] w-full h-[396px] relative flex justify-center m-auto mt-[148px]
      max-md:max-w-[480px] max-md:h-[410px] max-md:overflow-visible max-md:mt-14"
      style={{
        background: isBig ? `url(${JoinUsers})` : `url(${JoinUsersMobile})`,
        backgroundPosition: isBig ? "top left" : "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col text-center max-md:m-auto max-md:max-w-[202px]">
        <h2 className="mb-2 pt-[150px] text-white text-5xl max-md:text-4xl">
          <T keyName="components.landingPage.saveYourSpot.title" />
        </h2>
        <h3 className="mb-11 text-white text-xl max-md:mb-9 max-md:text-lg">
          <T keyName="components.landingPage.saveYourSpot.subtitle" />
        </h3>
      </div>
    </div>
  );
};

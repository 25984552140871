import { T } from "@tolgee/react";
import { PopupSkeleton } from "@/components/Popup/PopupSkeleton";
import { CoinsOut } from "@/assets/illustrations";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Inputs";
import { useContext } from "react";
import { AuthContext } from "@/providers/Auth/context";

export const ReclaimSuccess: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!;

  const navigate = useNavigate();

  return (
    <PopupSkeleton showClose onCloseLink="/overview/payments/wallet">
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={CoinsOut} alt="" />
        </div>
        <h1 className="text-2xl mb-4 font-medium">
          <T
            keyName="components.payments.wallet.reclaimSuccess.title"
            params={{
              balanceavailable: () =>
                currentUser!.balanceAvailable?.toFixed(2) ?? "0.00",
            }}
          />
        </h1>
        <h6 className="text-sm text-gray-shuttle mb-4">
          <T keyName="components.payments.wallet.reclaimSuccess.info" />
        </h6>

        <div className="flex justify-center mt-8">
          <Button
            onClick={() =>
              navigate("/overview/payments/wallet", { state: "refetch" })
            }
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T keyName="components.payments.wallet.reclaimSuccess.btn" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  );
};

import { useContext, useEffect, useState } from "react";
import { PopupSkeleton } from "@/components/Popup/PopupSkeleton";
import { FirstScreen } from "./FirstScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { SecondScreen } from "./SecondScreen";
import { SocialLogIn } from "@/components/SocialLogin";
import { AuthContext } from "@/providers/Auth/context.tsx";

export const Signup: React.FC = () => {
  // Actual signup is done on SecondScreen
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    // Get the referrer code from query params
    // and store it in local storage for later use when signing up
    // TODO: fix below abomination
    const referrerCode = location.search.split("=")[1];
    if (typeof referrerCode === "string" && referrerCode.length > 0) {
      localStorage.setItem("referrerCode", referrerCode);
    }
  }, [location.search]);

  useEffect(() => {
    if (auth?.currentUser) {
      navigate("/overview/subscriptions");
    }
  }, [auth, navigate]);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
  });
  const [step, setStep] = useState<"first" | "second">("first");

  return (
    <PopupSkeleton showClose onClose={() => navigate("/")}>
      <div className="w-[480px] h-full max-md:w-full">
        {step === "first" ? (
          <FirstScreen
            handleSubmit={(values) => {
              setUser({ ...user, ...values });
              setStep("second");
            }}
          />
        ) : (
          <SecondScreen user={user} />
        )}
      </div>
      <SocialLogIn type="signup" />
    </PopupSkeleton>
  );
};

import { useContext, useState } from "react";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { T, useTranslate } from "@tolgee/react";
import { ImgProfile } from "@/components/ImgProfile";
import { PopupSkeleton } from "@/components/Popup";
import { Button } from "@/components/Inputs";
import { AuthContext } from "@/providers/Auth/context";
import { toastError } from "@/utils/toast";
import { gql } from "@/gql";
import { ErrorMessage, Form, Formik } from "formik";
import { TextareaAutosize } from "@mui/material";
import * as yup from "yup";

const CANCEL_JOIN_SERVICE = gql(`
  mutation cancelService($serviceId: String!, $feedback: String!) {
    service: cancelService(serviceId: $serviceId, feedback: $feedback) {
      id
    }
  }
`);

export const LeaveSubscription: React.FC<{
  onClose: () => void;
  nextPaymentDate: string;
}> = ({ onClose, nextPaymentDate }) => {
  const { t } = useTranslate();
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const [cancelled, setCancelled] = useState(false);
  const [cancelService] = useMutation(CANCEL_JOIN_SERVICE);

  const handleCancel = async (feedback: string) => {
    try {
      await cancelService({ variables: { serviceId: id!, feedback } });
      setCancelled(true);
    } catch (e) {
      console.error(e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      toastError((e as any)?.message ?? e ?? "An error occurred");
    }
  };

  return (
    <PopupSkeleton showClose={!cancelled} onClose={onClose}>
      <Formik
        initialValues={{ feedback: "" }}
        validationSchema={yup.object({
          feedback: yup
            .string()
            .min(8, t("form.inputs.errors.feedback.length"))
            .required(t("form.inputs.errors.feedback.required")),
        })}
        onSubmit={({ feedback }) => handleCancel(feedback)}
      >
        {(formik) => (
          <Form className="w-[450px] h-full max-md:w-full p-10 text-center">
            <div className="flex justify-center mb-6">
              <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3"></div>
              <div className="mx-2 border border-dashed border-bombay rounded p-2">
                <ImgProfile
                  img={currentUser?.avatar}
                  className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50"
                />
              </div>
              <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3"></div>
            </div>

            <h1 className="text-2xl mb-4">
              <T
                keyName={
                  cancelled
                    ? "components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.leaveSuccessfully.title"
                    : "components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.title"
                }
              />
            </h1>
            <p className="text-gray-100 mb-4">
              <T
                keyName={
                  cancelled
                    ? "components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.leaveSuccessfully.info"
                    : "components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.info"
                }
                params={{
                  days: () =>
                    !cancelled
                      ? moment(nextPaymentDate).diff(moment(), "days")
                      : moment(nextPaymentDate).toNow(true),
                }}
              />
            </p>

            {cancelled ? (
              <h6 className="text-xs text-gray-shuttle-soft mb-8">
                <T keyName="components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.leaveSuccessfully.tip" />
              </h6>
            ) : (
              <>
                <TextareaAutosize
                  name="feedback"
                  className="w-full border border-gray-shuttle rounded-md p-4 mt-2 resize-none"
                  onChange={formik.handleChange}
                  minRows={5}
                  maxRows={5}
                  placeholder={t("form.inputs.placeholders.feedback")}
                />

                <ErrorMessage
                  name="feedback"
                  render={(msg) => (
                    <span className="text-pink-brink">{msg}</span>
                  )}
                />
              </>
            )}

            <div className="flex justify-center gap-6 mt-8">
              {!cancelled && (
                <Button
                  onClick={onClose}
                  className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
                >
                  <T keyName="components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.cancel" />
                </Button>
              )}
              {cancelled ? (
                <Button
                  onClick={() =>
                    navigate(`/overview/subscriptions/join/${id}`, {
                      state: { refetch: true },
                    })
                  }
                  className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
                >
                  <T keyName="components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.leaveSuccessfully.btn" />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
                >
                  <T keyName="components.joinSubscription.joinDetails.serviceDetails.leaveSubscription.confirm" />
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </PopupSkeleton>
  );
};

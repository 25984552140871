import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import "./main.css";
import { TranslationProvider } from "./providers/Translation/provider";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { AuthProvider } from "./providers/Auth/provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/react";
import { GOOGLE_CLIENT_ID } from "@/consts.ts";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ApolloProvider client={client}>
        <TranslationProvider>
          <AuthProvider>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <RouterProvider router={router} />
              <ToastContainer />
            </GoogleOAuthProvider>
          </AuthProvider>
        </TranslationProvider>
      </ApolloProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);

import { T } from "@tolgee/react";
import { ArrowFront } from "@/assets/icons";
import { PopupSkeleton } from "@/components/Popup";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ImgProfile } from "@/components/ImgProfile";
import moment from "moment";
import { gql } from "@/gql";
import invariant from "tiny-invariant";

const SLOT_DETAILS = gql(`
  query slotDetails($slotId: ID!) {
    slot(where: { id: $slotId }) {
      paymentDueDate
      service {
        provider {
          price {
            offerReturn
          }
        }
      }
      usedBy {
        fullName
        avatar
      }
    }
  }
`);

export const SlotUserRemove: React.FC = () => {
  const { slotId } = useParams<{ slotId: string }>();
  invariant(slotId, "Slot ID should be provided by the route");

  const { data } = useQuery(SLOT_DETAILS, {
    variables: { slotId },
  });

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-6">
          <ImgProfile
            img={data?.slot?.usedBy?.avatar}
            className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50"
          />
          <h6 className="text-xs text-gray-shuttle">
            {data?.slot?.usedBy?.fullName}
          </h6>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.overview.removeUserOptionsPopup.title"
            params={{
              username: () => data?.slot?.usedBy?.fullName ?? "User",
            }}
          />
        </h3>

        <div className="flex flex-col gap-4">
          <Link
            to={`../user/block/${slotId}`}
            className="bg-iron/50 w-full flex items-center text-left gap-2 p-4
            hover:bg-blue-dodger/20 [&_path]:hover:stroke-white
            [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger"
          >
            <h5 className="text-sm flex-grow">
              <T keyName="components.offerSubscription.offerDetails.overview.removeUserOptionsPopup.afterPayment" />
            </h5>
            <h6 className="text-sm text-pink-brink">
              <T
                keyName="components.offerSubscription.offerDetails.overview.removeUserOptionsPopup.daysLeft"
                params={{
                  days: () =>
                    moment(data?.slot.paymentDueDate).diff(moment(), "days"),
                }}
              />
            </h6>
            <ArrowFront />
          </Link>
          <Link
            to={`../user/refund/${slotId}`}
            className="bg-iron/50 w-full flex items-center text-left gap-2 p-4
            hover:bg-blue-dodger/20 [&_path]:hover:stroke-white
            [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger"
          >
            <h5 className="text-sm flex-grow">
              <T keyName="components.offerSubscription.offerDetails.overview.removeUserOptionsPopup.refund" />
            </h5>
            <h6 className="text-sm text-pink-brink">
              <T
                keyName="components.offerSubscription.offerDetails.overview.removeUserOptionsPopup.price"
                params={{
                  currency: () => "€",
                  amount: () =>
                    data?.slot.service.provider.price.offerReturn.toFixed(2) ??
                    "0.00",
                }}
              />
            </h6>
            <ArrowFront />
          </Link>
        </div>
      </div>
    </PopupSkeleton>
  );
};

import { T } from "@tolgee/react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { UnreadCounter } from "@/components/UnreadCounter";

export const TabsHeader: React.FC<{
  routes: {
    path: string;
    label: string;
    showNotification?: boolean;
  }[];
  type?: "join" | "offer";
  serviceId?: string;
}> = ({ routes, type, serviceId }) => {
  return (
    <div className="flex gap-12 border-b border-bombay max-md:gap-0 max-md:justify-between max-md:px-4">
      {routes.map((route) => (
        <NavLink
          to={route.path}
          key={route.path}
          className={({ isActive }) =>
            twMerge(
              "text-lg text-gray-shuttle font-medium pb-2 relative max-md:pb-4 max-md:text-base",
              isActive && "text-black border-b-[3px] border-b-black",
            )
          }
        >
          {route.showNotification && (
            <UnreadCounter
              serviceId={serviceId}
              serviceType={type}
              className="absolute -top-2 -right-4"
            />
          )}
          <T keyName={route.label} />
        </NavLink>
      ))}
    </div>
  );
};

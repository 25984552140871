export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error ?? "An error occured");
    reader.readAsDataURL(file);
  });
};

export const fileUrlToBase64 = (url: string): Promise<string> => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.open("GET", url);
  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error ?? "An error occurred");
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = (error) => reject(error ?? "An error occurred");
    xhr.send();
  });
};

import { T } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SubscriptionPopupContainer } from "./PopupContainer";
import { LinkButton } from "@/components/Inputs/Button";
import { useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { Bear, Error, Plane } from "@/assets/illustrations";
import { ImgProfile } from "@/components/ImgProfile";
import { Check } from "@/assets/icons";
import { Avatar1 } from "@/assets/images";
import { twJoin } from "tailwind-merge";
import { gql } from "@/gql";

const JOIN_SERVICE_SELECTION_INFO = gql(`
  query servicesAvailableToJoin($providerId: String) {
    services: servicesNotOwnerOrJoined(providerId: $providerId) {
      id
      slug
      provider {
        id
        name
      }
      owner {
        id
        fullName
        avatar
        slug
      }
    }
  }
`);

export const JoinSubscriptionServicePopup: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  if (!id) navigate("/overview/subscriptions");

  const [viewMore, setViewMore] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const { data, loading } = useQuery(JOIN_SERVICE_SELECTION_INFO, {
    variables: { providerId: id },
  });

  useEffect(() => {
    if (data?.services && data.services.length >= 1) {
      setSelectedId(data.services[0].id);
    }
  }, [data]);

  let content;
  if (loading) {
    content = <Spinner />;
  } else if (!data?.services || data.services.length === 0) {
    content = (
      <>
        <div className="flex justify-center mb-6">
          <img src={Error} alt="" />
        </div>

        <h1 className="text-3xl font-medium mb-2 text-center">
          <T keyName="emptyStates.noProfilesAvailable.title" />
        </h1>
        <h3 className="text-gray-100 text-center mb-2">
          <T keyName="emptyStates.noProfilesAvailable.subTitle" />
        </h3>

        <p className="text-sm text-gray-shuttle-soft mb-4 m-auto">
          <T keyName="emptyStates.noProfilesAvailable.info" />
        </p>

        <div className="flex justify-end items-center gap-4 max-md:flex-col max-md:justify-center">
          <LinkButton to="/overview/subscriptions/new-offer">
            <T keyName="emptyStates.noProfilesAvailable.link" />
          </LinkButton>
        </div>
      </>
    );
  } else {
    const recommended = data?.services[0];

    content = (
      <>
        <h1 className="text-2xl font-medium mb-2">
          <T keyName="components.joinSubscription.steps.availableNearYou.title" />
        </h1>
        <h6 className="text-gray-shuttle-soft mb-8 text-sm max-md:text-base">
          <T keyName="components.joinSubscription.steps.availableNearYou.subtitle" />
        </h6>

        {!viewMore && (
          <div className="flex items-center relative box-border py-3 px-2 rounded-2xl bg-blue-dodger/15 mb-12">
            <img className="w-[130px] max-md:w-[90px]" src={Bear} alt="" />
            <img
              className="absolute w-[97px] right-[35px] top-[-43px]"
              src={Plane}
              alt=""
            />
            <div className="ml-[19px]">
              <h2 className="text-lg font-medium text-gray-100 mb-2">
                <T
                  keyName="components.joinSubscription.steps.availableNearYou.recommendation.title"
                  params={{
                    username: () => recommended.owner.fullName.split(" ")[0],
                  }}
                />
              </h2>
              <h6 className="text-sm text-gray-shuttle-soft">
                <T
                  keyName="components.joinSubscription.steps.availableNearYou.recommendation.subtitle"
                  params={{
                    username: () => recommended.owner.fullName.split(" ")[0],
                  }}
                />
              </h6>
            </div>
          </div>
        )}

        <div className="text-gray-shuttle-soft text-xs mb-2">
          <T
            keyName={
              viewMore
                ? "components.joinSubscription.steps.availableNearYou.ourRecommendation"
                : "components.joinSubscription.steps.availableNearYou.sendARequest"
            }
            params={{
              username: () => recommended.owner.fullName.split(" ")[0],
            }}
          />
        </div>

        <JoinCell
          avatar={recommended.owner.avatar ? recommended.owner.avatar : Avatar1}
          username={recommended.owner.fullName}
          highlighted={viewMore}
          selected={selectedId === recommended.id}
          callback={() => setSelectedId(recommended.id)}
        />
        {!viewMore ? (
          <button
            type="button"
            className="text-left text-gray-shuttle-soft text-xs mt-4"
            onClick={() => setViewMore(true)}
          >
            <T keyName="components.joinSubscription.steps.availableNearYou.moreResults" />
          </button>
        ) : (
          <>
            <div className="text-gray-shuttle-soft text-xs mt-8 mb-2">
              <T keyName="components.joinSubscription.steps.availableNearYou.allOffer" />
            </div>
            <div className="flex flex-col max-h-[185px] box-border overflow-y-scroll max-md:max-h-max">
              {/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {data.services.slice(1).map((service: any) => (
                <JoinCell
                  key={service.id}
                  avatar={service.owner.avatar ? service.owner.avatar : Avatar1}
                  username={service.owner.fullName}
                  highlighted={false}
                  selected={selectedId === service.id}
                  callback={() => setSelectedId(service.id)}
                />
              ))}
            </div>
          </>
        )}

        <div className="md:hidden h-28" />
        <div className="flex justify-end items-center gap-4 max-md:justify-center max-md:fixed max-md:bg-white max-md:bottom-0 max-md:w-full max-md:h-28 md:mt-auto max-md:inset-x-0">
          <h6 className="text-xs text-gray-shuttle-soft max-md:text-base">
            <T keyName="components.joinSubscription.steps.howDoesThisWork.soundsGreat" />
          </h6>
          <LinkButton
            to={`/overview/subscriptions/new-join/${id}/service/${selectedId}/payment`}
          >
            <T keyName="components.joinSubscription.steps.howDoesThisWork.btn" />
          </LinkButton>
        </div>
      </>
    );
  }

  return (
    <SubscriptionPopupContainer type="join" providerId={id}>
      {content}
    </SubscriptionPopupContainer>
  );
};

const JoinCell: React.FC<{
  avatar: string;
  username: string;
  highlighted: boolean;
  selected: boolean;
  callback: () => void;
}> = (props) => (
  <div
    onClick={() => props.callback()}
    className={twJoin(
      "border-b border-gray-400 flex items-center relative cursor-pointer max-md:py-5",
      props.selected ? "bg-blue-dodger/15" : "hover:bg-blue-dodger/5",
    )}
  >
    {props.highlighted && (
      <img className="absolute right-[62px] top-[-43px]" src={Plane} alt="" />
    )}
    <div className="py-4 pl-2 mr-4 max-md:py-0 max-md:pl-0 max-md:pb-2 max-md:w-auto">
      <ImgProfile img={props.avatar} className="w-10 h-10" />
    </div>
    <div className="flex-grow text-sm">{props.username}</div>
    <div className="mr-2 cursor-pointer flex items-center">
      <Check
        className={
          props.selected
            ? "[&_circle]:fill-blue-dodger [&_circle]:stroke-blue-dodger [&_path]:stroke-white [&_path]:block"
            : "[&_path]:hidden"
        }
      />
    </div>
  </div>
);

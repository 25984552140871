import { T, useTranslate } from "@tolgee/react";
import { useQuery } from "@apollo/client";
import { Spinner } from "@/components/Spinner";
import { ServicesGrid } from "@/components/Services/ServicesGrid";
import { StateTabs } from "@/components/Tabs/StateTabs";
import { PopupOutlet } from "@/components/Popup";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { gql } from "@/gql";
import invariant from "tiny-invariant";

const GET_SERVICES = gql(`
  query getServices {
    services: me {
      id
      servicesOwned {
        id
        provider {
          id
          name
          brand
          price {
            id
            commission
            offerReturn
            fullPrice
          }
          numberOfSlots
        }
        numFreeSlots
        slots {
          id
          status
        }
      }
      servicesJoined {
        id
        owner {
          id
          firstName
        }
        provider {
          id
          name
          brand
          price {
            id
            fullPrice
          }
        }
      }
    }
  }
`);

export const SubscriptionsPage: React.FC = () => {
  const { t } = useTranslate();
  const { data, loading, refetch } = useQuery(GET_SERVICES);

  const { state } = useLocation();
  useEffect(() => {
    if (state === "refetch") refetch();
  }, [refetch, state]);

  if (loading) return <Spinner />;

  invariant(data, "Data should be loaded");

  const servicesJoined = data.services.servicesJoined!.map(
    ({ id, provider, owner }) => {
      const { price, brand } = provider;
      const { fullPrice } = price;
      const { firstName } = owner;
      return {
        id,
        text: t("components.joinSubscription.services.title", {
          username: () => firstName,
        }),
        info: t("components.joinSubscription.services.info", {
          price: () => Number(fullPrice).toFixed(2),
        }),
        img: brand,
      };
    },
  );
  const servicesOwned = data.services.servicesOwned!.map(
    ({ id, provider, slots }) => {
      const { price, numberOfSlots, brand } = provider;
      const usedSlots = slots.filter((slot: { status: string }) => {
        return slot.status === "IN_USE";
      });

      return {
        id,
        text: t("components.offerSubscription.services.title"),
        info: t("components.offerSubscription.services.info", {
          currency: () => "€",
          currentearns: () => price.offerReturn * usedSlots.length,
          price: () => (price.offerReturn * numberOfSlots).toFixed(2),
          month: () => t("components.offerSubscription.services.month"),
        }),
        img: brand,
      };
    },
  );

  const joinGrid = <ServicesGrid type="join" services={servicesJoined} />;
  const offerGrid = <ServicesGrid type="offer" services={servicesOwned} />;

  const tabs: React.ComponentProps<typeof StateTabs>["tabs"] = [
    {
      label: <T keyName="components.joinSubscription.title" />,
      component: joinGrid,
      type: "join",
    },
    {
      label: <T keyName="components.offerSubscription.title" />,
      component: offerGrid,
      type: "offer",
    },
  ];

  return (
    <>
      <div className="md:hidden max-md:py-4">
        <StateTabs tabs={tabs} initialIndex={state?.tabIndex} />
      </div>
      <div className="max-md:hidden grid grid-cols-2 h-full">
        {joinGrid}
        {offerGrid}
      </div>
      <PopupOutlet />
    </>
  );
};

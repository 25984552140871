import { T } from "@tolgee/react";
import { useLocation } from "react-router-dom";
import { PopupSkeleton } from "@/components/Popup";
import { LinkButton } from "@/components/Inputs/Button";
import { ImgProfile } from "@/components/ImgProfile";

export const ServiceRemoved: React.FC = () => {
  const {
    state: { name, brand },
  } = useLocation();

  return (
    <PopupSkeleton>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3"></div>
          <div className="mx-2 border border-dashed border-bombay rounded p-2">
            <ImgProfile img={brand} />
          </div>
          <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3"></div>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.details.removeServiceSuccess.title"
            params={{ service: () => name }}
          />
        </h3>
        <h6 className="text-gray-shuttle-soft text-sm mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.details.removeServiceSuccess.subTitle"
            params={{ service: () => name }}
          />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <LinkButton to="/overview/subscriptions" state={"refetch"}>
            <T keyName="components.offerSubscription.offerDetails.details.removeServiceSuccess.btn" />
          </LinkButton>
        </div>
      </div>
    </PopupSkeleton>
  );
};

import { gql } from "@/gql";

export const GET_ME = gql(`
  query getMe {
    user: me {
      id
      username
      firstName
      lastName
      fullName
      email
      slug
      avatar
      referralCode
      zipCode
      country
      balanceAvailable
      balanceTransfered
      totalBalance
      walletStatus
      referralTotalEarns
      ibanStatus
      idDocumentsStatus
      ibanStatus
      documentsStatus
      firstDocumentStatus
      firstDocumentType
      secondDocumentStatus
      secondDocumentType
      nationality
      dateOfBirth
    }
  }
`);

export const SUB_ME = gql(`
  subscription user {
    user: subscribeMe {
      id
      username
      firstName
      lastName
      fullName
      email
      slug
      avatar
      referralCode
      zipCode
      country
      balanceAvailable
      balanceTransfered
      totalBalance
      walletStatus
      referralTotalEarns
      ibanStatus
      idDocumentsStatus
      ibanStatus
      documentsStatus
      firstDocumentStatus
      firstDocumentType
      secondDocumentStatus
      secondDocumentType
      nationality
      dateOfBirth
    }
  }
`);

export const LOGIN = gql(`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user
      accessToken
      refreshToken
    }
  }
`);

export const SOCIAL_LOGIN = gql(`
  mutation socialLogin($provider: SocialAuthProviders!, $token: String!) {
    socialLogin(provider: $provider, token: $token) {
      user
      accessToken
      refreshToken
    }
  }
`);

export const SIGNUP = gql(`
  mutation signup(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $avatar: String!
    $referrerCode: String
    $source: String!
  ) {
    signUp(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        avatar: $avatar
        referrerCode: $referrerCode
        source: $source
      }
    )
  }
`);

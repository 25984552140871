import { Navigate, RouteObject } from "react-router-dom";
import { publicRoutes } from "./public";
import { subscriptionRoutes } from "./subscriptions";
import { Overview } from "@/pages/overview";
import { paymentsRoutes } from "./payments";
import { settingsRoutes } from "./settings";
import { rewardsRoutes } from "./rewards";
import { ChatProvider } from "@/providers/Chat/provider";

export const routes: RouteObject[] = [
  ...publicRoutes,
  {
    path: "/overview",
    element: (
      <ChatProvider>
        <Overview />
      </ChatProvider>
    ),
    errorElement: <Navigate to="/" replace />,
    children: [
      ...subscriptionRoutes,
      ...paymentsRoutes,
      ...settingsRoutes,
      ...rewardsRoutes,
    ],
  },
];

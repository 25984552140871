import { RouteObject } from "react-router-dom";
import { Rewards as RewardsIcon } from "@/assets/icons";
import { Rewards } from "@/pages/overview/rewards";

export const rewardsRoutes: RouteObject[] = [
  {
    path: "rewards",
    element: <Rewards />,
    handle: {
      label: "navbar.links.rewards",
      icon: RewardsIcon,
    },
  },
];

import { useEffect, useState } from "react";

export const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    const width = window.innerWidth;
    setWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 640;
  const isTabletPortrait = width > 640 && width <= 768;
  const isTabletLandscape = width > 768 && width <= 1280;
  const isDesktop = width > 1280;
  const isSmall = isMobile || isTabletPortrait;
  const isBig = isDesktop || isTabletLandscape;

  return {
    isMobile,
    isTabletPortrait,
    isTabletLandscape,
    isDesktop,
    isSmall,
    isBig,
  };
};

import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { Plus64 } from "@/assets/icons";

export interface ServiceSlotProps {
  id: string;
  text: string;
  info: string;
  img: string;
}

export const ServiceSlot: React.FC<ServiceSlotProps & { linkTo: string }> = ({
  text,
  info,
  img,
  linkTo,
}) => {
  return (
    <Link
      to={linkTo}
      className="w-full flex flex-col justify-center items-center text-center cursor-pointer"
    >
      <div
        className={twJoin(
          "w-40 h-40 border-gray-400 hover:border-blue-dodger flex " +
            "justify-center items-center max-md:w-[172px] max-md:h-[172px]",
          img ? "rounded flex border" : "border border-dashed box-border",
        )}
      >
        {!img ? (
          <Plus64 />
        ) : (
          <img
            className="max-h-[86px] max-w-[86px] m-auto max-md:max-w-[60%] max-md:max-h-[60%]"
            src={img}
            alt="service logo"
          />
        )}
      </div>
      <p className="whitespace-nowrap text-sm max-md:text-base text-gray-100 mt-2">
        {text}
      </p>
      <div className="h-5 whitespace-nowrap text-xs max-md:text-sm text-gray-shuttle-soft">
        {info}
      </div>
    </Link>
  );
};

import { T, useTranslate } from "@tolgee/react";
import { PopupSkeleton } from "@/components/Popup/PopupSkeleton";
import { SinglBoxReferral } from "@/assets/illustrations";
import { useContext, useState } from "react";
import { AuthContext } from "@/providers/Auth/context";
import { Button } from "@/components/Inputs";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookMedia, TwitterMedia, WhatsappMedia } from "@/assets/icons";

export const InviteFriends: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!;
  const referralLink = `${import.meta.env.VITE_REFERRAL_BASE_URL}${currentUser?.referralCode}`;

  const { t } = useTranslate();

  const [copied, setCopied] = useState(false);

  return (
    <PopupSkeleton showClose>
      <div className="w-[480px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={SinglBoxReferral} alt="" />
        </div>
        <h1 className="text-2xl mb-4">
          <T keyName="components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.title" />
        </h1>
        <h2 className="text-sm text-gray-shuttle">
          <T keyName="components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.info" />
        </h2>

        <div className="mt-8 flex gap-4">
          <p className="flex-grow p-4 bg-iron/20 border border-bombay/20 text-xs text-gray-shuttle text-left">
            {referralLink}
          </p>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(referralLink);
              setCopied(true);
            }}
          >
            <T
              keyName={`components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.${
                copied ? "copied" : "copy"
              }`}
            />
          </Button>
        </div>

        <h6 className="text-sm text-gray-100 my-4">
          <T keyName="components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.shareLink" />
        </h6>

        <div className="flex gap-2 justify-center">
          <FacebookShareButton url={referralLink} hashtag={"#partizi"}>
            <FacebookMedia />
          </FacebookShareButton>
          <WhatsappShareButton
            url={referralLink}
            title={t(
              "components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.shareQuote",
            )}
          >
            <WhatsappMedia />
          </WhatsappShareButton>
          <TwitterShareButton
            url={referralLink}
            hashtags={["partizi"]}
            title={t(
              "components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.shareQuote",
            )}
          >
            <TwitterMedia />
          </TwitterShareButton>
        </div>
      </div>
    </PopupSkeleton>
  );
};
